@media screen and (max-width: 1200px) {
  .frame {
    &-teams-button {
      display: block;
    }
  }
}

@media screen and (max-width: 420px) {
  .switch {
    font-size: 13px;
  }

  .frame {
    &-teams {
      z-index: 10;
      width: 100vw;
      height: calc(100vh - #{$mobile-footer-height});

      &-button {
        display: none;
      }
    }

    &-dynamic {
      inset-inline-start: 0;
      width: 100vw !important;
      height: calc(100vh - #{$mobile-footer-height});
      flex-shrink: 0;
      background: #19222D;

      &:before {
        backdrop-filter: unset;
      }

      &-title {
        padding: 10px 0;
      }

      &-header {
        padding: 10px 20px 20px;
      }

      &-body {
        .drop-here {
          margin: 0 20px;
        }
      }

      &-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        position: fixed;
        width: 100%;
        z-index: 10;
        height: $mobile-footer-height;
        bottom: $mobile-footer-height;
        background: rgba(#19222d, 0.5);
        backdrop-filter: blur(10px);
        box-shadow: 0 -5px 10px rgba(#000, 0.1);

        & ~ .frame-dynamic-body {
          margin-bottom: 56px;
        }
      }

      &-list {
        padding: 0 20px;
      }

      &-detail {
        .frame-dynamic-body {
          .order-details {
            padding: 5px 20px 20px;
          }
        }
      }

      &-detail,
      &-courier {
        .frame-detail {
          &__body {
            padding: 0 20px 20px;
          }
        }
      }

      &._hide {
        display: none;
      }
    }
  }

  .popup {
    width: 100vw;
    height: calc(100vh - #{$mobile-footer-height});

    &__body {
      padding: 25px 30px;
    }

    &._mobile-full-screen {
      & > .popup__body {
        border-radius: 0;
        width: 100%;
        height: 100%;
        flex-direction: column;

        .header {
          display: none;
        }
      }

      .popup {
        &__header {
          border-bottom: 1px solid #2C3745;
          width: 100%;
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 0;
          height: $mobile-header-height;
        }

        &__contents,
        .contents {
          @include gray-scrollbar('y');
          height: calc(100vh - #{$mobile-footer-height} - #{$mobile-header-height});
        }

        &__back-btn,
        &__close-btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: 20px;
            width: 20px;
          }
        }

        &__back-btn {
          padding: 20px 0 20px 20px;
        }

        &__close-btn {
          margin-inline-start: auto;
          padding: 20px;
        }

        &__title {
          padding-inline-start: 20px;
        }
      }
    }

    &._mobile-centered {
      .popup {
        &__body {
          width: 90vw;
          height: auto;
          max-height: 90vh;
          border-radius: 10px;
          overflow: visible;
        }

        &__title {
          padding: 0;
        }

        &__close {
          font-size: 24px;
          inset-inline-end: calc(5vw - 20px);
          inset-inline-start: unset;
          top: -45px;
          z-index: 2;
        }
      }
    }

    &-settings {
      border: none;

      .popup__body {
        .content {
          .settings-frame {
            &__header,
            .header {
              display: none;
            }

            &__body,
            .body {
              padding: 20px;
              height: calc(100vh - 61px);

              &__item,
              .item {
                .prop {
                  font-size: 13px;
                }

                &__user-cards,
                .user-cards {
                  &__user-card,
                  .user-card {
                    font-size: 12px;
                    padding: 11px 9px 11px 14px;

                    &__top,
                    .top {
                      margin-bottom: 4px;
                    }

                    &__bottom,
                    .bottom {
                      &__phone,
                      .phone {
                        &__value {
                          margin-inline-end: 3px;
                        }

                        &__copy {
                          font-size: 1.3em;
                        }
                      }

                      &__roles,
                      .roles {
                        &::before {
                          margin: 0 5px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &-add-member {
      .popup {
        &__body {
          padding: 0;
          width: auto;
          height: auto;
        }
      }

      &__title {
        display: none;
      }

      &__content {
        padding: 10px 20px 0;
        margin: 0;
        @include gray-scrollbar('y');

        .available-members {
          height: auto;
          margin-top: 10px;

          .member-card {
            font-size: 13px;
          }
        }

        .member-search {
          box-shadow: 0 0 0 1px #2c3745;

          .input-wrapper {
            position: initial;
            z-index: 3;

            input {
              padding-inline-start: 0;
              border-radius: 0 4px 4px 0;
              box-shadow: none;
            }
          }

          &__btn-submit {
            width: auto;
            height: 100%;
            border-radius: 4px 0 0 4px;
            inset-inline-start: 0;
            font-size: 24px;
            padding: 0 10px;
            background: #202934;
            margin: 0;
            border: 0;
            cursor: pointer;
            color: #eee;
            font-weight: 500;
          }
        }
      }

      &__buttons {
        position: fixed;
        width: 100%;
        bottom: $mobile-footer-height;
        background: rgba(#232e3c, 0.5);
        backdrop-filter: blur(10px);
        padding: 10px 20px;
      }
    }

    &-advanced-search {
      .popup {
        &__body {
          &__content,
          .content {
            padding: 20px;

            &.result-table-wrapper {
              border-radius: 0;
            }

            .input-blocks {
              gap: 15px;

              &__input-block,
              .input-block {
                display: flex;
                flex-direction: column;
                gap: 15px;

                &:first-child {
                  margin-bottom: 10px;

                  .input-wrapper {
                    input {
                      height: 46px;
                    }

                    .input-text__button {
                      font-size: 24px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .alert {
    bottom: unset;
    top: 15px;
    animation: bounceInDown 0.5s backwards;

    &__body {
      bottom: unset;
      width: 90vw;
      top: 0;
    }
  }

  .header {
    &-search {
      &__dropdown,
      .dropdown {
        height: calc(100vh - #{$mobile-footer-height} - #{$header_height});
      }
    }
  }

  .team-list {
    height: calc(100vh - #{$header_height} - #{$mobile-footer-height}) !important;
    margin-bottom: 50px;
  }

  .edit-courier-form {
    padding: 0 20px;
  }

  .auth {
    padding: 0;

    &-popover {
      width: 100vw;
      height: 100vh;
      max-width: unset;
      max-height: unset;
      border-radius: 0;
      padding: 0 0 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__title {
        font-size: 18px;
        margin-top: 16px;
      }

      form {
        width: 85vw;
      }
    }
  }

  .courier {
    &-title {
      padding: 0 0 0 12px;

      &__info {
        width: 50px;
      }
    }
  }

  .input-wrapper {
    .input {
      &-date {
        &__input-popup,
        .input-popup {
          width: 100%;
        }
      }

      &-time {
        &__input-popup,
        .input-popup {
          width: 48%;
        }

        &._range {
          .input-popup {
            &._end {
              inset-inline-start: unset;
              inset-inline-end: 0;
            }
          }
        }
      }
    }
  }
}
