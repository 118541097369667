@import "config";

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  font-family: $font_pt_root_ui;
  color: #eee;
  font-size: 14px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: #19222d;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

* {
  box-sizing: border-box;
  outline: 0;

  &:before,
  &:after {
    box-sizing: border-box;
  }
}

a {
  color: #333;
  text-decoration: none;

  &:hover {
    color: #333;
    text-decoration: none;
  }
}

strong {
  font-weight: 700;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #333 !important;
  background-clip: content-box !important;
}

.content {
  &-layout {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
  }
}

button {
  font-weight: inherit;
  font-family: inherit;
  border: none;
  outline: none;
  color: inherit;
  background: unset;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

input {
  font-family: inherit;
  border: none;
  outline: none;
  color: inherit;
  background: #202934;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #2c3745;
  transition: box-shadow 0.2s;
  width: 100%;
  height: 40px;
  padding: 0 45px 0 16px;
  font-size: 14px;

  &:hover {
    box-shadow: 0 0 0 1px #3a4758;
  }

  &:focus {
    box-shadow: 0 0 0 3px #3a4758;
  }

  &[disabled] {
    box-shadow: none !important;
  }

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    margin: 0;
    appearance: none;
    -webkit-appearance: none;
  }
}
