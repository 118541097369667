@import 'mixins';
@import 'config';
@import 'icon_names';

.expandableHeader {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  &-title {
    font-size: 16px;
    font-weight: 500;
    &-icon {
      margin-inline-start: 10px;
      transition: all 0.2s;
      &.__expanded {
        transform: rotateX(180deg);
      }
    }
  }
}

.button {
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 8px 16px;
  text-decoration: none;
  color: #eee;
  background: none;
  position: relative;
  flex-shrink: 0;
  border: 0;
  line-height: 1;
  transition: opacity 0.2s ease-in-out, background 0.2s ease-in-out, color 0.2s ease-in-out;
  font-family: $font_pt_root_ui;
  cursor: pointer;

  &._primary {
    background: #08b375;

    &:hover {
      background: #08aa70;
    }

    &._disabled,
    &[disabled] {
      background: #5f7e73;
      color: #a6a8bf;
    }
  }

  &._secondary {
    background: #596370;

    &:hover {
      background: #515b67;
    }

    &._disabled,
    &[disabled] {
      background: #596370;
      color: #a6a8bf;
    }
  }

  &._red {
    background: #bd2525;

    &:hover {
      background: #a92121;
    }

    &._disabled,
    &[disabled] {
      background: #8a3a3a;
      color: #a6a8bf;
    }
  }

  &._block {
    display: flex;
  }

  &._flat {
    background: transparent;

    &:hover {
      background: #2c3745;
    }

    &._green {
      color: #12c383;
    }
  }

  &:disabled,
  &._disabled {
    pointer-events: none !important;
  }

  &-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #eee;
    font-size: 24px;
    cursor: pointer;
    background-color: #202934;
    transition: background-color 0.2s;
    position: relative;

    &._active,
    &:hover {
      background-color: #08aa70;
    }

    &__label {
      position: absolute;
      inset-inline-end: -5px;
      top: -5px;
      font-size: 10px;
      color: #141c26;
      width: 17px;
      height: 17px;
      border-radius: 5px;
      background: #eee;
      align-items: center;
      justify-content: center;
      font-family: $font_pt_root_ui;
      display: flex;
    }

    &._unassigned {
      position: absolute;
      inset-inline-end: 12px;
      top: 75px;
      transition: all 0.2s;

      &._active {
        background-color: #ffd600;
        color: #19222d;
        box-shadow: 0 0 24px rgba(219, 121, 30, 0.6);

        &:hover {
          background-color: #ffe664;
        }
      }
    }

    &._teamless {
      position: absolute;
      inset-inline-end: 12px;
      top: 130px;
      transition: all 0.2s;

      &._active {
        background-color: #aadb1e;
        color: #19222d;
        box-shadow: 0 0 24px rgba(143, 219, 30, 0.6);

        &:hover {
          background-color: #d6ff64;
        }
      }
    }
  }
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0;

  &._with-icon-btn {
    .input-text__wrap input {
      padding-inline-start: 0;
    }
  }

  &._with-clear-btn {
    .input-text__wrap input {
      padding-inline-end: 0;
    }
  }

  &__label {
    font-size: 12px;
    color: #7b868a;
    margin-bottom: 5px;
  }

  .input {
    &-text {
      $placeholder_color: #7b868a;
      font-family: $font_pt_root_ui;

      &::-webkit-input-placeholder {
        color: $placeholder_color;
      }

      &::-moz-placeholder {
        color: $placeholder_color;
      }

      &:-moz-placeholder {
        color: $placeholder_color;
      }

      &:-ms-input-placeholder {
        color: $placeholder_color;
      }

      &__errorText {
        color: rgb(244, 67, 54);
        font-size: 12px;
        line-height: 20px;
      }

      &__wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #202934;
        border-radius: 4px;
        box-shadow: 0 0 0 1px #2c3745;
        line-height: 40px;
        padding: 0;
        position: relative;

        .input-text {
          padding: 0 16px;
          background: transparent;
          border: none;
          box-shadow: none;
          outline: none;
          width: 100%;
        }

        &._flat {
          .input-text {
            background: rgba(0, 0, 0, 0.1);
            padding: 10px;
            border: none;
            box-shadow: none;

            &:hover,
            &:focus {
              box-shadow: none;
            }
          }
        }

        &._error {
          .input-text {
            box-shadow: 0 0 0 1px rgb(244, 67, 54) !important;
          }
        }
      }

      &__clear,
      &__button {
        width: 24px;
        height: 24px;
        font-size: 20px;
        z-index: 2;
        transition: color 0.2s;
        cursor: pointer;
        margin: 0 10px;
        padding: 0;
      }

      &__button {
        color: #eee;

        &:hover {
          color: #08aa70;
        }
      }

      &__clear {
        color: #8a8b91;

        &:hover {
          color: #eee;
        }
      }

      &__check {
        position: absolute;
        inset-inline-end: 10px;
        width: 24px;
        height: 24px;
        font-size: 20px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        color: #08b375;
        pointer-events: none;

        &:has(+ [type='tel']) {
          right: 10px;
        }
      }
    }

    &-checkbox {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      border: 1px solid #a6a8bf;
      border-radius: 4px;
      transition: border-color 0.2s, background 0.2s;
      color: #fff;
      font-size: 16px;
      cursor: pointer;

      &:before {
        opacity: 0;
        transition: opacity 0.2s;
      }

      &._checked {
        border-color: #08b375;
        background: #08b375;

        &:before {
          opacity: 1;
        }

        &._disabled {
          background: #a6a8bf;
          border-color: #a6a8bf;
        }
      }

      &._disabled {
        pointer-events: none;
        background: #a6a8bf;
      }
    }

    &-select {
      $background: #202934;
      position: relative;
      background: $background;
      border-radius: 4px;
      box-shadow: 0 0 0 1px #2c3745;
      transition: box-shadow 0.2s;
      height: 40px;

      &:hover {
        box-shadow: 0 0 0 1px #3a4758;
      }

      &._active {
        box-shadow: 0 0 0 3px #3a4758;
        z-index: 10;

        .input-select__icon {
          transform: translateY(-50%) scaleY(1);
        }

        .input-select__list {
          display: block;
        }
      }

      &._with-search {
        .val {
          .input-select__item {
            pointer-events: all;

            .search-input {
              display: flex;
              align-items: center;
              height: 100%;
              width: 100%;
              @include text-ellipsis();

              &__icon {
                font-size: 1.4em;
                margin-inline-end: 10px;
              }

              &__input {
                width: 100%;
                border: none;
                box-shadow: none;
                padding: 0 5px 0 0;
              }

              &__clear {
                color: #7b868a;
                opacity: 0;
                transform: scale(0.5);
                transition: 200ms ease all;
                cursor: pointer;
                margin-inline-start: auto;

                &._active {
                  opacity: 1;
                  transform: scale(1);
                }
              }
            }
          }
        }

        .list {
          .input-select__item {
            &._no-result {
              cursor: default;
              pointer-events: none;
            }
          }
        }
      }

      &._disabled {
        box-shadow: none !important;
      }

      &__val,
      .val {
        position: relative;
        color: #7b868a;
        cursor: pointer;

        &._active {
          .input-select__item {
            color: #eee;
          }
        }

        .input-select__item {
          pointer-events: none;
          padding-inline-end: 45px;
        }
      }

      &__icon {
        position: absolute;
        inset-inline-end: 16px;
        top: 50%;
        transform: translateY(-50%) scaleY(-1);
        width: 16px;
        height: 16px;
        font-size: 14px;
        pointer-events: none;
        transition: transform 0.3s;
        color: #7b868a;
      }

      &__list,
      .list {
        position: absolute;
        top: 100%;
        background: $background;
        inset-inline-start: 0;
        width: 100%;
        display: none;
        overflow: auto;
        z-index: 8;
        animation: fadeIn 0.3s backwards;
        border-radius: 4px;
        box-shadow: 0 0 0 3px #3a4758;
        margin-top: 3px;
        max-height: 140px;
      }

      &__item {
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
        color: #7b868a;
        transition: color 0.2s;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        & + .input-select__item {
          border-top: 1px solid #3a4758;
        }

        &:hover {
          color: #eee;
        }
      }
    }

    &-date {
      position: relative;

      &__input-popup,
      .input-popup {
        width: 310px;
        height: 378px;

        .react-datepicker {
          $side-padding: 10px;
          border-radius: 12px;
          box-shadow: 0 4px 16px rgba(#000, 0.2);
          background: #232e3c;
          position: relative;

          &__navigation,
          .navigation {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: auto;
            padding: 15px $side-padding;

            &__buttons,
            .buttons {
              display: flex;

              &__button {
                display: flex;
                align-items: center;
                transition: 200ms ease all;

                &:hover {
                  transform: scale(0.9);
                }

                &:active {
                  transform: scale(1.1);
                }
              }
            }

            &__date {
              font-weight: 500;
            }
          }

          &__day-names {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            padding: 15px $side-padding 0;
            border-top: 1px solid #2c3745;
          }

          &__day-name {
            text-align: center;
            color: #b1b2b9;
          }

          &__month {
            display: flex;
            flex-direction: column;
            padding: 10px $side-padding;
            font-size: 14px;
          }

          &__week {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
          }

          &__day {
            text-align: center;
            font-weight: 500;
            cursor: pointer;
            padding: 8px 5px;
            margin: 4px 2px;
            border-radius: 8px;
            transition: 200ms ease all;

            &:hover {
              background: #2c3745;
            }

            &--selected {
              background: #12c383;
              color: #eee !important;

              &:hover {
                background: #12c383;
              }
            }

            &--outside-month {
              color: #596370;

              &.react-datepicker__day--in-range {
                background: #212a35;
              }

              &.react-datepicker__day--range-start {
                background: #1d2631;
                border-right: 4px solid #212a35;
              }

              &.react-datepicker__day--range-end {
                background: #1d2631;
                border-left: 4px solid #212a35;
              }
            }

            &--in-range {
              background: rgba(#12c383, 0.45);
              border-radius: 0;
              margin: 4px 0;
              color: #eee !important;
            }

            &--range-start,
            &--range-end {
              background: #12c383;
              border-radius: 8px;

              &:hover {
                background: #12c383;
              }
            }

            &--range-start {
              border-radius: 8px 0 0 8px;
              border-right: 4px solid #1b715c;
              margin-inline-start: 2px;

              &.react-datepicker__day--range-end {
                border-radius: 0;
              }
            }

            &--range-end {
              border-radius: 0 8px 8px 0;
              border-left: 4px solid #1b715c;
              margin-inline-end: 2px;
            }

            &--disabled {
              background: rgba(166, 168, 191, 0.1);
              color: rgba(#596370, 0.5);
              border-radius: 0;
              cursor: default;
              pointer-events: none;
              margin: 4px 0;
            }

            &--today {
              color: #12c383;
              border: 1px solid #12c383;
            }
          }

          &__today-button {
            border-top: 1px solid #2c3745;

            &::before {
              display: block;
              content: 'Today';
              height: 100%;
              width: 100%;
              cursor: pointer;
              text-align: center;
              color: #12c383;
              padding: 15px $side-padding;
              transition: 0.2s ease color;
            }

            &:hover::before {
              color: #0e9465;
            }
          }
        }
      }
    }

    &-time {
      position: relative;

      &__input-popup,
      .input-popup {
        width: 168px;

        .react-timepicker {
          border-radius: 12px;
          box-shadow: 0 4px 16px rgba(#000, 0.2);
          background: #232e3c;
          height: 100%;
          width: 100%;
          overflow: hidden;

          &__header {
            padding: 15px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: 500;
            border-bottom: 1px solid #2c3745;
          }

          &__body {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: minmax(0, 1fr);
          }

          &__footer {
            border-top: 1px solid #2c3745;
          }

          &__buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 10px;

            .button {
              width: 40%;
              height: 34px;
            }
          }

          &__column {
            display: flex;
            flex-direction: column;
            height: 297px;
            overflow-y: scroll;
            @include hidden-scrollbar-y();
          }

          &__cell {
            font-weight: 500;
            text-align: center;
            padding: 8px 0;
            cursor: pointer;
            transition: 200ms ease all;
            border-radius: 5px;
            min-height: 33px;

            &:hover {
              background: #596370;
            }

            &._selected {
              background: #2c3745;
              border-radius: 0;
            }

            &._empty {
              cursor: default;
              pointer-events: none;

              &:hover {
                background: transparent;
              }
            }
          }
        }
      }

      &._range {
        .input-popup {
          &._end {
            inset-inline-start: 200px;
          }
        }
      }
    }
  }

  .display {
    position: relative;
    display: flex;
    align-items: center;

    &__input {
      color: #7b868a;

      &._active {
        color: inherit;
      }
    }

    &__icon {
      cursor: pointer;
      position: absolute;
      inset-inline-end: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transform: scale(0);
      transition: 200ms ease all;
      height: 24px;
      width: 24px;

      div {
        height: 100%;
        width: 100%;
      }

      &._active {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .input-popup {
    position: absolute;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: 100ms $back-in-ease all;
    z-index: 10;
    top: 50px;

    &._opened {
      opacity: 1;
      transform: scaleY(1);
    }
  }
}

.popover {
  $transition_offset: -25px;
  position: absolute;
  background: #232e3c;
  box-shadow: 0 4px 34px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  padding: 28px 32px 32px;
  z-index: 20;
  width: 380px;

  &-filter {
    top: 50px;
    inset-inline-end: 10px;

    .popover-title {
      padding-inline-end: 8px;
    }
  }

  &-add-courier {
    &-form {
      margin-top: 24px;
    }
  }

  &-add-team {
    &-form {
      margin-top: 24px;
    }

    .input-wrapper {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-menu {
    top: 50px;
    inset-inline-start: 8px;
    width: 260px;
    padding: 16px 8px;

    &__item {
      height: 44px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      border-radius: 4px;
      transition: background 0.2s;
      cursor: pointer;
      color: #eee;
      font-size: 14px;

      &:hover {
        background: #2c3745;
        color: #eee;

        .icon {
          color: #08b375;
        }
      }

      .icon {
        font-size: 24px;
        margin-inline-end: 8px;
        display: block;
        transition: color 0.2s;
      }
    }

    &__line {
      height: 1px;
      background: #2c3745;
      margin: 8px 0 10px;
    }
  }

  &-add-team,
  &-add-courier,
  &-dropdown {
    top: 50px;
    inset-inline-start: 133px;
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__name {
      font-weight: 500;
      color: #fff;
      font-size: 20px;
      line-height: 25px;
    }

    &__icon {
      font-size: 20px;
      color: #eee;
      margin-inline-start: 24px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  &-buttons {
    display: flex;
    margin: 24px -4px 0;

    .button {
      margin: 0 4px;
      width: 100%;
      flex-shrink: 1;
    }
  }

  &-enter {
    opacity: 0;

    &._in-top {
      transform: translate(0, $transition_offset);
    }

    &._in-left {
      transform: translate($transition_offset, 0);
    }

    &._in-right {
      transform: translate($transition_offset * -1, 0);
    }
  }

  &-enter-active {
    opacity: 1;
    transition: transform 0.4s ease-out, opacity 0.4s ease-out;

    &._in-top,
    &._in-left,
    &._in-right {
      transform: translate(0, 0);
    }
  }

  &-exit {
    opacity: 1;

    &._in-top,
    &._in-left,
    &._in-right {
      transform: translate(0, 0);
    }
  }

  &-exit-active {
    opacity: 0;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;

    &._in-top {
      transform: translate(0, $transition_offset);
    }

    &._in-left {
      transform: translate($transition_offset, 0);
    }

    &._in-right {
      transform: translate($transition_offset * -1, 0);
    }
  }
}

.text-with-highlight {
  color: inherit;

  &__part {
    &._active {
      color: #eee;

      &.text-with-highlight__part._green {
        color: #12c383;
      }
    }
  }
}

.checkbox {
  border-radius: 4px;
  padding: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #a6a8bf;
  transition: 200ms $back-in-ease all;
  cursor: pointer;

  .icon {
    font-size: 1.2em;
    transition: 200ms $back-in-ease transform;
    transform: scale(0);
  }

  &._active {
    background: #08b375;
    border-color: transparent;

    .icon {
      transform: scale(1);
    }
  }
}

.popup {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  display: flex;

  &__bg {
    position: fixed;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    z-index: 1;
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
  }

  &__header,
  .header {
    padding: 20px 20px 20px 30px;
    border-bottom: 1px solid #2c3745;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #232e3c;
    height: auto;
    position: initial;
    border-radius: 12px 12px 0 0;

    &__title {
      font-size: 1.7em;
      font-weight: 500;
      max-width: 400px;
      @include text-ellipsis();
    }

    &__close {
      color: #8a8b90;
      transition: color 0.2s;
      cursor: pointer;
      font-weight: 700;
      font-size: 1.5em;
      padding: 10px;
      margin-inline-start: auto;

      &:hover {
        color: #eee;
      }
    }
  }

  &__content,
  .content {
    padding: 20px 30px;
    border-bottom: 1px solid #2c3745;

    &._light {
      background: #232e3c;
    }

    &._dark {
      background: #1d222e;
      border-radius: 0 0 12px 12px;
    }

    &:nth-last-child(1) {
      border: none;
    }
  }

  &__body {
    background: #232e3c;
    border-radius: 12px;
    padding: 28px 40px;
    width: 458px;
    flex-shrink: 0;
    position: relative;
    z-index: 2;
    margin: auto;
    box-shadow: 0 0 30px rgba(#000, 0.2);
  }

  &__close {
    position: absolute;
    inset-inline-start: 100%;
    top: 0;
    width: 40px;
    height: 40px;
    font-size: 20px;
    color: #8a8b90;
    transition: color 0.2s;
    cursor: pointer;
    font-weight: 700;

    &:hover {
      color: #eee;
    }
  }

  &-advanced-search {
    .popup__body {
      width: 900px;
      padding: 0;

      .content {
        &.result-table-wrapper {
          &._hidden {
            & > * {
              display: none;
            }
          }
        }

        &.search-button-wrapper {
          padding: 0;

          button {
            padding: 0;
            border-radius: 0;
            width: 100%;
          }
        }

        .input-blocks {
          display: flex;
          flex-direction: column;
          gap: 20px;

          &__input-block,
          .input-block {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: minmax(0, 1fr);
            gap: 10px;
          }
        }

        .result-list {
          padding-bottom: 20px;

          &__title {
            margin-bottom: 15px;
          }

          &__list {
            display: flex;
            flex-direction: column;
            gap: 20px;
          }

          &__item {
            background: linear-gradient(
              90deg,
              rgba(52, 63, 78, 0.5) 15.28%,
              rgba(52, 63, 78, 0.5) 50%
            );
            border-radius: 4px;
            padding: 10px 20px;
          }

          &__rows {
            display: flex;
            flex-direction: column;
          }

          &__row,
          .row {
            padding: 10px 0;
            border-bottom: 1px solid #2c3745;
            display: flex;

            &:last-child {
              border-bottom: 0;
            }

            &__title,
            &__value {
              width: 50%;
            }

            &__title {
              font-weight: 500;
            }

            &__value {
              display: flex;
              gap: 5px;
              color: #7b868a;

              &._empty {
                color: #7b868a;

                &::after {
                  content: '_';
                }
              }
            }
          }
        }

        .result-table {
          padding-bottom: 20px;

          &__title {
            margin-bottom: 15px;
          }

          &__table {
            font-size: 12px;
            max-height: 226px;
            padding-inline-end: 5px;
            border-radius: 5px;
            @include gray-scrollbar('y');
          }

          &__row {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: 1fr 1fr 1.3fr 1fr 1.3fr 1fr 1fr 1.3fr 0.7fr;
            column-gap: 10px;
            padding: 15px 10px;
          }

          &__head {
            font-weight: 500;

            .result-table__row {
              border-bottom: 2px solid #2c3745;
            }

            .result-table__cell,
            .cell {
              display: flex;
              align-items: center;
              cursor: pointer;
              gap: 5px;

              &._active {
                .cell__sort-icon {
                  opacity: 1;
                  transform: rotateY(180deg) rotateZ(180deg) scale(1);
                }

                &._asc {
                  .cell__sort-icon {
                    transform: rotateZ(180deg) rotateZ(180deg) scale(1);
                  }
                }
              }

              &__sort-icon {
                opacity: 0;
                transform: scale(0);
                transition: 200ms $back-in-ease all;
              }
            }
          }

          &__body {
            .result-table__row {
              border-top: 1px solid #2c3745;
              transition: 200ms ease background;
              cursor: pointer;

              &:hover {
                background: rgba(#202934, 0.6);
              }

              &._selected {
                background: rgba(#202934, 1);
              }

              &:first-child {
                border-top: none;
              }
            }
          }

          &__cell {
            @include multiline-ellipsis(2);

            &._empty {
              color: #7b868a;

              &::after {
                content: '_';
              }
            }
          }
        }
      }
    }
  }

  &-complete {
    &__sign {
      margin: 0 auto 10px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #eee;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 700;
      color: #202934;
    }

    &__text {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      color: #fff;
      width: 180px;
      margin: 0 auto;
    }

    &__buttons {
      display: flex;
      margin: 25px -4px 0;

      .button {
        margin: 0 4px;
        flex-shrink: 1;
        white-space: nowrap;
        width: 100%;
      }
    }
  }

  &-settings {
    $border-style: 1px solid #2c3745;

    &__switch-wrapper {
      padding: 20px 20px 0;
    }

    .popup__body {
      display: flex;
      flex-direction: row;
      padding: 0;
      height: 550px;
      width: 800px;
      overflow: hidden;

      .sidebar {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        width: 38%;
        padding: 20px 10px;
        border-right: $border-style;

        &__item,
        .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          transition: 200ms ease all;
          padding: 10px;
          border-radius: 5px;
          cursor: pointer;
          width: 100%;

          &__icon {
            font-size: 1.8em;
            margin-inline-end: 10px;
            transition: 200ms ease color;
          }

          &:hover,
          &._active {
            background: #2c3745;
            color: #eee;

            .icon {
              color: #08b375;
            }
          }
        }
      }

      .contents {
        @include gray-scrollbar('y');
      }

      .content {
        width: 100%;
        padding: 0;

        .settings-frame {
          display: none;
          width: 100%;

          &._active {
            display: block;
          }

          &__header,
          .header {
            position: relative;
            background: transparent;
            height: auto;
            padding: 20px 30px;

            &__back {
              margin-inline-end: 10px;
              cursor: pointer;
              transition: 200ms ease background;
              border-radius: 5px;
              padding: 10px;
              transform: rotate(270deg);

              &:hover {
                background: #3c4655;
              }
            }
          }

          &__body,
          .body {
            padding: 20px 30px;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            @include hidden-scrollbar-y();
            height: 468px;

            &__item,
            .item {
              &__top,
              .top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;

                &__label {
                  color: #7b868a;
                }

                &__button,
                .button {
                  color: #08aa70;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  height: auto;
                  padding: 0;

                  &__icon {
                    font-size: 1.2em;
                    margin-inline-end: 8px;
                  }

                  &:hover {
                    color: #05c582;
                  }
                }
              }

              &__select {
                margin-bottom: 20px;
              }

              &__info,
              .info {
                display: flex;
                flex-direction: column;
                row-gap: 10px;

                &__prop,
                .prop {
                  display: flex;
                  flex-direction: row;
                  align-items: center;

                  &__icon {
                    color: #08b375;
                    font-size: 1.7em;
                    margin-inline-end: 10px;
                  }
                }
              }

              .cards {
                display: flex;
                flex-direction: column;
                row-gap: 4px;
                border-radius: 5px;

                .card {
                  background: #343f4e;
                  border-radius: 5px;
                }
              }

              &__team-cards,
              .team-cards {
                &__team-card,
                .team-card {
                  display: flex;
                  flex-direction: row;
                  padding: 13px 12px;
                  position: relative;
                  transition: 200ms ease background;
                  cursor: pointer;
                  min-height: 43px;

                  &:hover {
                    background: #3c4655;

                    .icon {
                      color: #eee;
                    }
                  }

                  &__name {
                    @include text-ellipsis(true);
                    max-width: 200px;
                    font-weight: 500;
                  }

                  &__duty {
                    margin-inline-start: 45%;
                    position: absolute;
                    color: #7b868a;
                  }

                  &__icon {
                    color: #596370;
                    margin-inline-start: auto;
                    transform: rotate(90deg);
                    transition: 200ms ease color;
                  }
                }
              }

              &__user-cards,
              .user-cards {
                &__user-card,
                .user-card {
                  display: flex;
                  flex-direction: column;
                  padding: 13px 9px 13px 14px;

                  &__top,
                  .top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 5px;

                    & > div {
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    }

                    &__left,
                    .left {
                      &__name {
                        margin-inline-end: 5px;
                      }

                      &__status {
                        color: #7b868a;

                        &._accent {
                          color: #08b375;
                        }
                      }
                    }

                    &__right,
                    .right {
                      &__icon {
                        padding: 0 5px;
                        transition: 200ms ease color;
                        font-size: 1.3em;
                        color: #7b868a;
                        cursor: pointer;

                        &:hover {
                          color: #eee;
                        }
                      }
                    }
                  }

                  &__bottom,
                  .bottom {
                    display: flex;
                    color: #7b868a;

                    &__phone,
                    .phone {
                      display: flex;
                      align-items: center;

                      &__value {
                        margin-inline-end: 5px;
                      }

                      &__copy {
                        font-size: 1.5em;
                        color: #4d4f57;
                        cursor: pointer;
                        transition: 200ms ease color;

                        &:hover {
                          color: #7b868a;
                        }
                      }
                    }

                    &__roles,
                    .roles {
                      &::before {
                        content: '•';
                        margin: 0 10px;
                      }

                      &__role {
                        &::after {
                          content: ', ';
                        }

                        &:last-child::after {
                          content: '';
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &__close {
      display: none;
    }
  }

  &-image-view {
    z-index: 40;
    height: 100vh !important;
    @include hidden-scrollbar-y();

    .popup__body {
      overflow: hidden;
      width: auto;
      padding: 10px;

      .popup-image-view__image {
        max-width: 85vw;
        max-height: 85vh;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(#000, 0.2);
      }
    }
  }

  &-add-member {
    .popup__body {
      width: 550px;
      flex-direction: column;
      height: auto;
      padding: 28px 40px;
    }

    &__title {
      font-size: 1.5em;
      font-weight: 500;
    }

    &__content {
      margin: 20px 0;

      .member-search {
        display: flex;
        align-items: center;
        position: relative;
        height: 40px;

        .input-wrapper {
          position: absolute;
          width: 100%;
          inset-inline-start: 0;

          input {
            padding-inline-start: 45px;
          }
        }

        &__btn-submit {
          z-index: 2;
          width: 24px;
          height: 24px;
          inset-inline-start: 0;
          margin: 0 10px;
          font-size: 24px;
          background: transparent;
          border: 0;
          cursor: pointer;
          color: #eee;
          font-weight: 500;
        }
      }

      .selected-members {
        display: flex;
        margin-top: 10px;
        gap: 5px;
        height: 25px;
        animation: scaleIn 200ms cubic-bezier(0.215, 0.61, 0.355, 1);

        &._wrap {
          height: auto;

          .list {
            overflow-x: hidden;
            flex-wrap: wrap;

            .item {
              &._view-all-btn {
                background: transparent;
                height: auto;
                padding: 3px 5px;

                &._limited-items-shown {
                  .item__icon {
                    transform: rotate(0);
                  }
                }

                .item__icon {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }

        &__list,
        .list {
          display: flex;
          gap: 5px;
          border-radius: 5px;
          flex: 1;
          @include hidden-scrollbar-y();

          &__item,
          .item {
            background: #08b375;
            border-radius: 30px;
            display: flex;
            align-items: center;
            padding: 3px 10px;
            gap: 7px;
            flex-wrap: nowrap;
            white-space: nowrap;
            font-size: 0.95em;
            font-weight: 500;

            &__close {
              cursor: pointer;
              font-weight: 900;
              font-size: 0.9em;
            }
          }
        }

        button,
        .button {
          color: #08aa70;
          cursor: pointer;
          transition: 200ms ease color;
          white-space: nowrap;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            color: #05c582;
          }
        }
      }

      .available-members {
        display: flex;
        flex-direction: column;
        gap: 3px;
        height: 190px;
        margin-top: 20px;
        border-radius: 5px;
        @include hidden-scrollbar-x();

        .member-card {
          background: #343f4e;
          cursor: pointer;
          padding: 10px 12px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          font-size: 0.95em;
          gap: 10px;

          &._mobile {
            .member-card {
              &__content {
                display: flex;
                flex-direction: column;
                gap: 5px;
              }

              &__name {
                width: 50vw;
              }

              &__phone {
                width: 100px;
                padding-inline-end: 5px;
              }

              &__roles {
                &::before {
                  content: '•';
                }
              }

              &__footer {
                display: flex;
              }
            }
          }

          &__name {
            font-weight: 500;
            width: 130px;
            display: block;
          }

          &__phone {
            width: 120px;
          }

          &__name,
          &__phone,
          &__roles {
            color: #7b868a;
            @include text-ellipsis();
          }

          &__roles {
            display: flex;
            gap: 5px;
            flex-wrap: nowrap;

            & > span {
              &::after {
                content: ',';
              }

              &:last-child::after {
                content: '';
              }
            }
          }

          &__checkbox {
            margin-inline-start: auto;
          }
        }
      }
    }

    &__buttons {
      display: flex;
      gap: 10px;

      button {
        flex: 1;
      }
    }
  }

  &-add-courier {
    &__form {
      margin-top: 20px;
    }
  }

  &-delete-member {
    .popup__body {
      height: 200px;
      width: 400px;
      display: flex;
      flex-direction: column;
      padding: 30px;
    }

    &__sign {
      margin: 0 auto 10px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #eee;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 700;
      color: #202934;
    }

    &__text {
      text-align: center;
      font-size: 16px;
      color: #fff;
      margin: 10px 0 20px;
    }

    &__buttons {
      display: flex;
      gap: 10px;

      button {
        flex: 1;
      }
    }
  }

  &-edit-courier {
    .popup__body {
      width: 350px;

      .title {
        font-size: 1.7em;
        font-weight: 500;
        margin-bottom: 20px;
      }

      .name {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-bottom: 28px;

        &__label {
          font-weight: 500;
        }
      }

      .edit-courier-form {
        padding: 0;
      }
    }
  }

  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }

  .disabled {
    pointer-events: none;

    * {
      pointer-events: none;
    }
  }
}

.badge {
  font-size: 10px;
  color: #a6a8bf;
  flex-shrink: 0;
  height: 17px;
  line-height: 1;
  display: flex;
  align-items: center;
  padding: 0 4px;
  background: #202934;
  border-radius: 5px;
}

.frame {
  &-map {
    position: relative;

    &._clear-gmap {
      .gmnoprint,
      .gm-style-cc {
        display: none !important;
      }
    }
  }

  &-teams {
    width: $frame_teams_width;
    position: absolute;
    height: 100%;
    inset-inline-end: 0;
    z-index: 20;
    flex-shrink: 0;
    border: none;
    display: flex;
    flex-direction: column;
    background: #19222d;

    &._hidden {
      display: none;
    }

    &-button {
      display: none;
      cursor: pointer;
      position: absolute;
      inset-inline-end: $frame_teams_width - 5px;
      top: 50vh;
      transform: translate(0, -50%);
      font-size: 1.5em;
      font-weight: 600;
      transition: transform 0.2s;
      padding: 10px;
      border-radius: 10px 0 0 10px;
      z-index: 19;
      background: rgba(20, 28, 38, 0.8);

      &:before {
        display: block;
        transform: rotate(180deg);
      }

      &._hidden {
        inset-inline-end: -5px;

        &:before {
          transform: rotate(0);
        }
      }

      &:hover {
        transform: translate(-5px, -50%);
      }
    }
  }

  &-dynamic {
    position: absolute;
    top: 0;
    inset-inline-end: $frame_teams_width;
    height: 100%;
    background: rgba(25, 34, 45, 0.8);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: 0.1s;
    z-index: 15;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      pointer-events: none;
    }

    &._hide {
      opacity: 0;
      pointer-events: none;
      transform: translate(20%);
    }

    &._teamless,
    &._unassigned {
      width: 390px;
    }

    &._courier {
      width: 330px;
    }

    &._detail {
      width: 350px;
    }

    &-unassigned,
    &-teamless,
    &-detail,
    &-courier {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    }

    &-teamless,
    &-unassigned {
      position: absolute;
      inset-inline-end: 0;
      inset-inline-start: 0;
      height: 100%;
      width: 100%;
      z-index: 2;

      &._disabled {
        z-index: 1;
        opacity: 0;
        left: 350px;
        pointer-events: none;
      }
    }

    &-teamless {
      .courier-title {
        padding-inline-end: 12px;
      }
    }

    &-unassigned {
      .frame-dynamic-list {
        height: 100%;

        .courier {
          background: none;
          padding: 0;
          margin: 0;
          border: 0;
          height: 100%;
        }

        .courier__body {
          min-height: 200px;
        }
      }

      .courier__body {
        display: flex;
        padding: 0 16px 28px 28px;
      }
    }

    &-detail,
    &-courier {
      .frame-detail {
        &-subtitle {
          display: flex;
          align-items: center;
          margin-top: 4px;

          &__text,
          &__date {
            font-size: 14px;
            color: #a6a8bf;
          }

          &__dot {
            flex-shrink: 0;
            width: 2px;
            height: 2px;
            border-radius: 50%;
            margin: 0 6px;
            background: #c4c4c4;
          }

          &__status {
            font-size: 14px;
            color: #147ad9;

            &:first-letter {
              text-transform: uppercase;
            }
          }
        }

        &__task {
          display: flex;
          justify-content: space-between;
        }

        &__body {
          padding: 0 32px 28px 28px;
        }

        &__title {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          color: #fff;
          margin-bottom: 12px;

          &._line {
            overflow: hidden;
            margin-top: 20px;

            span {
              position: relative;

              &:before {
                content: '';
                height: 1px;
                width: 100vw;
                inset-inline-start: 100%;
                margin-inline-start: 11px;
                top: 50%;
                pointer-events: none;
                position: absolute;
                background: #2c3745;
              }
            }
          }
        }

        &-eta {
          &__title {
            font-size: 14px;
            line-height: 18px;
            color: #e9eaec;
          }

          &__text {
            font-size: 12px;
            color: #a6a8bf;
            margin-top: 4px;
          }

          &__arrow {
            margin: 8px 0;
            width: 7px;
            height: 25px;
            flex-shrink: 0;
            background: url($img_dir + 'arrow_v.svg') center center no-repeat;
            background-size: contain;
          }
        }

        &__line {
          height: 1px;
          margin: 20px 0;
          background: #2c3745;

          &._12 {
            margin: 12px 0;
          }

          &._bright {
            background: #a6a8bf;
          }
        }

        &__customer {
          font-size: 14px;
          font-weight: 500;
          color: #eee;
          margin-bottom: 5px;
        }

        &__phone {
          display: flex;
          align-items: center;
          color: #08b375;
          font-size: 14px;
          cursor: pointer;

          &:hover .icon {
            color: #eee;
          }

          .icon {
            width: 16px;
            height: 16px;
            margin-inline-start: 4px;
            font-size: 16px;
            color: #4d4f57;
            transition: color 0.2s;
          }
        }

        &__subtitle {
          font-size: 14px;
          line-height: 18px;
          color: #7b868a;
          margin-bottom: 4px;
        }

        &__age {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 4px;

          .frame-detail__subtitle {
            margin: 0 20px 0 0;
          }
        }

        &__text {
          font-size: 14px;
          line-height: 18px;
          color: #eee;

          .see-all {
            color: #08b375;
            transition: color 0.2s;
            cursor: pointer;
            white-space: nowrap;

            &:hover {
              color: #eee;
            }
          }
        }

        &-details {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .frame-detail__text {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &__sign {
            display: flex;
            margin-inline-start: 20px;
            align-items: center;
            color: #08b375;
            transition: color 0.2s;
            cursor: pointer;

            &:hover {
              color: #eee;
            }

            .icon {
              width: 16px;
              height: 16px;
              margin-inline-start: 2px;
              transform: rotate(90deg);
              font-size: 14px;
            }
          }
        }

        &__photo {
          display: block;
          border-radius: 5px;
          width: 100%;

          & + .frame-detail__photo,
          & + .frame-detail__photo-comment {
            margin-top: 12px;
          }
        }

        &__photo-comment {
          font-size: 14px;
          line-height: 18px;
          color: #eee;

          & + .frame-detail__photo,
          & + .frame-detail__photo-comment {
            margin-top: 12px;
          }
        }

        &-bag {
          padding: 12px 14px;
          border-radius: 4px;
          background: #202934;
          display: flex;
          align-items: center;
          min-height: 48px;
          overflow: hidden;

          & + .frame-detail-bag {
            margin-top: 4px;
          }

          > .icon {
            font-size: 24px;
            margin-inline-end: 8px;
          }

          &__title {
            font-size: 15px;
            font-weight: 500;
            color: #cdced3;

            &:first-letter {
              text-transform: uppercase;
            }
          }

          &__text {
            font-size: 12px;
            color: #e9e9e9;
            margin: 0 8px;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &__icons {
            display: flex;
            margin-inline-start: auto;
            justify-content: flex-end;
            align-items: center;
            flex-shrink: 0;

            .icon {
              width: 24px;
              height: 24px;
              color: #eee;
              border-radius: 50%;
              font-size: 16px;

              & + .icon {
                margin-inline-start: 4px;
              }
            }

            .icon-passport {
              background: #d39317;
            }

            .icon-pizza {
              background: #40b935;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                height: 16px;
                width: 16px;
              }
            }

            .icon-snowflake {
              background: #3571e8;
            }

            .icon-glass {
              background: #bd2577;
            }
          }

          .icon {
            &-waiting_for_pickup {
              color: #147ad9;
            }

            &-picked {
              color: #147ad9;
            }

            &-missing {
              color: #f52d2d;
            }

            &-delivered {
              color: #08b375;
            }

            &-rejected {
              color: #f8aa13;
            }

            &-returned {
              color: #08b375;
            }

            &-not_returned {
              color: #f52d2d;
            }
          }
        }

        &__rating {
          display: flex;
          align-items: center;

          &-rate {
            margin-inline-start: 12px;
            padding: 0 12px;
            background: #202934;
            border-radius: 50px;
            font-weight: 500;
            font-size: 14px;
            height: 26px;
            line-height: 26px;
            color: #eee;
            flex-shrink: 0;

            span {
              color: #7b868a;
            }
          }

          .icon {
            font-size: 24px;
            color: #cdced3;

            & + .icon {
              margin-inline-start: 2px;
            }

            &._fill {
              color: #f8aa13;
            }
          }
        }
      }

      .frame-dynamic-title__num {
        cursor: pointer;
        display: inline-block;

        &:hover .icon {
          color: #eee;
        }

        .icon {
          width: 20px;
          height: 20px;
          margin-inline-start: 8px;
          font-size: 20px;
          color: #4d4f57;
          transition: color 0.2s;
          display: inline-block;
          vertical-align: top;
          margin-top: 2px;
        }
      }
    }

    &-courier {
      .frame-detail-subtitle__status {
        margin-inline-start: 6px;
      }

      .frame-dynamic-title {
        justify-content: flex-start;

        &__close {
          margin-inline-start: auto;
        }

        &__edit-btn {
          font-size: 1.5em;
          border-radius: 10px;
          cursor: pointer;
          transition: 200ms ease all;
          padding: 10px;
          margin-inline-start: 10px;
          color: #7b868a;

          &:hover {
            background: #202934;
          }

          &._active {
            color: inherit;
          }
        }
      }
    }

    &-detail {
      .frame-dynamic-header {
        background: rgba(28, 40, 54, 0.9);
        display: flex;
        flex-direction: column;
        gap: 15px;

        .frame-dynamic-time {
          display: flex;
          flex-direction: column;
          gap: 5px;

          &__item,
          .item {
            font-size: 16px;
            display: flex;
            gap: 5px;

            &__label {
              color: #a6a8bf;
            }

            &__value,
            .value {
              display: flex;
              gap: 5px;

              &__main {
                font-weight: 500;
              }

              &__sub {
                font-weight: 400;
              }
            }
          }
        }

        .frame-dynamic-source {
          &__text {
            color: #a6a8bf;
            font-size: 12px;
          }
        }
      }

      .frame-dynamic-body {
        height: 100%;
        width: 100%;
        background: rgba(25, 34, 45, 0.9);
        position: relative;

        .feed,
        .order-details {
          transition: 200ms ease all;
          width: 100%;
          height: 100%;
          position: absolute;
          overflow-x: hidden;
          @include gray-scrollbar('y');

          &._active {
            transform: translateX(0);
            padding-bottom: 70px;
          }
        }

        .feed {
          transform: translateX(100%);
          padding: 10px 15px 10px 20px;

          &__item,
          .item {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 15px 5px 25px 40px;
            color: #8a8b91;
            border-radius: 5px;
            position: relative;

            &__dot {
              position: absolute;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: #12c383;
              inset-inline-start: 16px;
              box-shadow: 0 0 0 4px #19222d;
              margin-top: 4px;
            }

            &__line {
              position: absolute;
              height: 100%;
              width: 2px;
              background: #12c383;
              inset-inline-start: 20px;
              margin-top: 1px;
            }

            &__text {
              font-size: 16px;
              font-weight: 700;
            }

            &__body {
              margin-top: 5px;
            }

            &._picking-up {
              .item__body {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .eta {
                  white-space: nowrap;
                }

                .actual-time {
                  white-space: nowrap;

                  &__value {
                    margin-inline-start: 5px;
                    @include text-ellipsis();

                    &._earlier {
                      color: #12c383;
                    }

                    &._later {
                      color: #e2423a;
                    }
                  }
                }
              }
            }

            &._delivered {
              .item__body {
                display: flex;
                flex-direction: column;
                white-space: nowrap;
                gap: 5px;

                .eta {
                  &__diff {
                    max-width: 20px;
                    @include text-ellipsis();
                  }
                }
              }
            }

            &:last-child {
              background: rgba(8, 179, 117, 0.1);
              color: #eee;
              padding-bottom: 15px;

              .item__text {
                color: #12c383;
              }

              .item__line {
                display: none;
              }

              .item__dot {
                box-shadow: 0 0 0 4px #3b4f50;
              }
            }

            &._bad {
              color: #e2423a;
              background: rgba(#e2423a, 0.1);

              .item__text {
                color: #e2423a;
              }

              .item__line {
                z-index: 10;
                background: #e2423a;
                display: block;
                bottom: 50%;
                height: calc(100% / 2);
              }

              .item__dot {
                background: #e2423a;
                box-shadow: 0 0 0 4px #5e3736;
              }
            }

            ._separate::before {
              content: '•';
              padding: 0 7px;
              color: #8a8b91;
            }
          }
        }

        .order-details {
          transform: translateX(-100%);
          padding: 5px 25px 20px 28px;

          &__item,
          .item {
            border-bottom: 1px solid #1d2b3d;
            padding: 15px 0;

            &__title,
            .title {
              display: flex;
              align-items: flex-start;
              gap: 5px;
              position: relative;

              &._centered {
                align-items: center;
              }

              &._with-icons {
                .title__expand-icon {
                  margin-inline-start: 0;
                }
              }

              &__sub {
                color: #7b868a;
                white-space: nowrap;
              }

              &__main {
                font-weight: 500;
                @include text-ellipsis();
              }

              &__icons {
                display: flex;
                margin-inline-start: auto;
                gap: 5px;
                padding: 0 5px;
              }
            }

            &__content,
            .content {
              display: flex;
              flex-direction: column;
              gap: 10px;
              margin-top: 5px;

              &__header {
                .phone {
                  width: fit-content;
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  cursor: pointer;

                  &__value {
                    color: #08b375;
                  }

                  &__copy {
                    color: #4d4f57;
                    font-size: 1.2em;
                    transition: 200ms ease color;
                  }

                  &:hover {
                    .phone__copy {
                      color: #eee;
                    }
                  }
                }

                .address {
                  margin-top: 5px;
                  font-size: 12px;
                  color: #596370;
                  display: flex;
                  align-items: flex-start;
                  gap: 5px;
                  cursor: pointer;
                  &__sub {
                    font-size: 14px;
                    color: #7b868a;
                  }
                  &__value {
                    font-size: 14px;
                    color: #eee;
                  }
                  &__copy {
                    color: #4d4f57;
                    font-size: 16px;
                    transition: 200ms ease color;
                  }

                  &:hover {
                    .address__copy {
                      color: #eee;
                    }
                  }
                }
                .address__details {
                  display: flex;
                  gap: 5px;
                  align-items: center;

                  margin-top: 4px;
                  &__title {
                    font-size: 12px;
                    color: #7b868a;
                  }
                  &__value {
                    font-size: 14px;
                    color: #08b375;
                  }
                }
              }
              .payment-method {
                display: flex;
                gap: 5px;

                &__label {
                  color: #7b868a;
                  white-space: nowrap;
                }
                &__value {
                  font-weight: 500;
                  @include text-ellipsis();
                }
              }
            }

            &._expandable {
              padding: 0;

              &:hover {
                .title__expand-icon {
                  color: #2e9371;
                  transform: rotate(180deg) translateY(-3px);
                }
              }

              .title {
                cursor: pointer;
                padding: 15px 0;

                &__main {
                  @include text-ellipsis(false);
                }

                &__expand-icon {
                  transition: 200ms ease all;
                  color: #12c383;
                  transform: rotate(180deg);
                  margin-inline-start: auto;
                }
              }

              .content {
                transition: 200ms $back-in-ease all;
                transform-origin: top;
                opacity: 0;
                transform: scaleY(0);
                display: none;
                margin-bottom: 15px;
              }

              &._expanded {
                &:hover {
                  .title__expand-icon {
                    transform: rotate(0) translateY(3px);
                  }
                }

                .title {
                  &__main {
                    text-overflow: initial;
                    white-space: normal;
                    overflow: visible;
                  }

                  &__expand-icon {
                    transform: rotate(0);
                  }
                }

                .content {
                  transform: scaleY(1);
                  opacity: 1;
                  display: flex;
                }
              }
            }

            &._courier {
              .item__title,
              .title {
                &__icon {
                  transform: rotate(90deg);
                }
              }

              .order-tasks {
                display: flex;
                flex-direction: column;
                gap: 15px;
                margin-top: 10px;

                .order-task {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;

                  &__title {
                    color: #7b868a;
                    font-size: 12px;
                  }

                  &__photos,
                  .photos {
                    display: flex;
                    gap: 5px;
                    padding-bottom: 4px;
                    @include gray-scrollbar('x');

                    &__photo {
                      width: 100px;
                      border-radius: 5px;
                      cursor: pointer;
                    }
                  }

                  &__comment {
                    word-break: break-word;
                  }
                }
              }
            }

            &._parcels {
              .parcel-icons {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-shrink: 0;
                margin-inline-start: auto;

                & + .title__expand-icon {
                  margin-inline-start: 3px;
                }

                .icon {
                  width: 24px;
                  height: 24px;
                  color: #eee;
                  border-radius: 50%;
                  font-size: 16px;

                  & + .icon {
                    margin-inline-start: 4px;
                  }
                }

                .icon-passport {
                  background: #d39317;
                }

                .icon-pizza {
                  background: #40b935;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg {
                    height: 16px;
                    width: 16px;
                  }
                }

                .icon-snowflake {
                  background: #3571e8;
                }

                .icon-glass {
                  background: #bd2577;
                }
              }

              .content {
                gap: 8px;

                .parcel-bag {
                  padding: 12px 14px;
                  border-radius: 4px;
                  background: #202934;
                  display: flex;
                  align-items: center;
                  min-height: 48px;
                  overflow: hidden;

                  > .icon {
                    font-size: 24px;
                    margin-inline-end: 8px;
                  }

                  &__title {
                    font-size: 15px;
                    font-weight: 500;
                    color: #cdced3;

                    &:first-letter {
                      text-transform: uppercase;
                    }
                  }

                  &__text {
                    font-size: 12px;
                    color: #e9e9e9;
                    margin: 0 8px;
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }

                  .icon {
                    &-waiting_for_pickup {
                      color: #147ad9;
                    }

                    &-picked {
                      color: #147ad9;
                    }

                    &-missing {
                      color: #f52d2d;
                    }

                    &-delivered {
                      color: #08b375;
                    }

                    &-rejected {
                      color: #f8aa13;
                    }

                    &-returned {
                      color: #08b375;
                    }

                    &-not_returned {
                      color: #f52d2d;
                    }
                  }
                }
              }
            }

            &._products {
              .product-categories {
                display: flex;
                flex-direction: column;
                gap: 15px;

                &__category,
                .category {
                  &__title,
                  .title {
                    font-size: 12px;
                    padding: 0;
                    margin-bottom: 10px;
                    cursor: default;

                    &__name {
                      color: #7b868a;
                    }

                    &__count {
                      color: #eee;
                      font-weight: 500;
                    }
                  }

                  &__products,
                  .products {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    &__product,
                    .product {
                      display: flex;
                      align-items: center;
                      gap: 15px;

                      &__image-wrapper {
                        img {
                          height: 56px;
                          width: 56px;
                          border-radius: 5px;
                        }
                      }

                      &__details,
                      .details {
                        display: flex;
                        flex-direction: column;
                        gap: 7px;
                        margin-top: -5px;

                        &__name {
                          font-weight: 500;
                          font-size: 16px;
                        }

                        &__sku {
                          font-size: 14px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .frame-dynamic-footer {
        padding: 15px 28px;
        background: #19222d;
        box-shadow: none;
      }

      ._earlier {
        color: #12c383;
      }

      ._late {
        color: #d39317;
      }

      ._super-late {
        color: #e2423a;
      }
    }

    &-header {
      flex-shrink: 0;
      padding: 18px 28px;
    }

    &-body {
      height: 100%;
      padding-bottom: 10px;
      overflow-x: hidden;
      @include gray-scrollbar('y');

      .drop-here {
        margin: 0 16px 0 28px;

        & + .drop-here {
          margin-top: 20px;
        }
      }
    }

    &-frame {
      height: calc(100% - 168px);
    }

    &-footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      flex-shrink: 0;
      padding: 11px 32px;
      background: #202934;
      box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.08);
      z-index: 2;

      &__buttons {
        display: flex;
        width: 100%;
        gap: 5px;

        .button {
          width: 100%;
          flex-shrink: 1;
        }
      }
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__name {
        font-size: 20px;
        font-weight: 500;
        color: #eee;
        line-break: anywhere;
      }

      &__num {
        text-transform: uppercase;
        line-break: anywhere;
      }

      &__status {
        display: flex;
        align-items: center;
        margin-top: 5px;
      }

      &__close {
        width: 40px;
        height: 40px;
        font-size: 20px;
        color: #8a8b90;
        transition: color 0.2s;
        cursor: pointer;
        font-weight: 700;
        margin-inline-end: -10px;
        align-self: flex-start;

        &:hover {
          color: #eee;
        }
      }
    }

    &-info {
      margin-top: 10px;
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      line-height: 18px;
      color: #a6a8bf;

      .icon {
        width: 24px;
        height: 24px;
        margin-inline-end: 4px;
        color: #a6a8bf;
        font-size: 24px;
      }
    }

    &-list {
      padding: 0 16px 28px 28px;
    }
  }
}

[dir='rtl'] {
  .mobile-sidebar {
    transform: translateX(100%);
  }
}
.mobile-sidebar {
  position: absolute;
  width: $mobile-sidebar-width;
  height: calc(100vh - #{$mobile-footer-height});
  inset-inline-start: 0;
  top: 0;
  z-index: 20;
  background: #202934;
  transition: 200ms ease all;
  transform: translateX(-100%);

  &._opened {
    transform: translateX(0);
  }

  &__header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    border-bottom: 1px solid #2c3745;
  }

  &__title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
  }

  &__close-button {
    cursor: pointer;
  }

  &__menu-items {
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    gap: 5px;
    height: calc(98% - #{$mobile-footer-height});
  }

  &__menu-item,
  .menu-item {
    display: flex;
    flex-direction: column;
    position: relative;

    &._separate {
      &::before {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background: #2c374599;
        margin-bottom: 7px;
        margin-top: 7px;
      }
    }

    &._bottom {
      padding: 0 10px;
      position: absolute;
      inset-inline-start: 0;
      bottom: calc(80px + env(safe-area-inset-bottom) + #{$mobile-footer-height});
      width: 100%;
    }

    &__content {
      display: flex;
      align-items: center;
      gap: 15px;
      cursor: pointer;
      padding: 10px;
      border-radius: 5px;
      transition: 200ms ease all;

      &:hover {
        background: #2b3241;
      }
    }

    &__icon {
      font-size: 1.7em;
    }

    &__text {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.mobile-footer {
  position: absolute;
  height: $mobile-footer-height;
  background: #19222d;
  bottom: 0;
  inset-inline-start: 0;
  width: 100vw;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  z-index: 30;
  box-shadow: -5px 0 10px 0 rgb(0, 0, 0, 0.2);

  &__item,
  .item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    border-radius: 2px;
    cursor: pointer;
    height: 100%;
    transition: 100ms ease all;

    &:hover,
    &._active {
      background: #1f2a34;
    }

    &__icon {
      height: 22px;
      width: 22px;
      position: relative;
    }

    &__text {
      font-weight: 500;
    }

    &__count {
      position: absolute;
      border-radius: 4px;
      background: #202934;
      padding: 3px;
      color: #a6a8bf;
      inset-inline-end: -14px;
      top: -8px;
    }
  }

  &__burger-button {
    font-size: 1.5em;
    background: #202934;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
  }

  &__map-button {
    height: auto;
    border-radius: 4px;
    margin-inline-start: auto;
    margin-inline-end: auto;

    &:hover {
      background: transparent;
    }
  }

  &__screen-buttons {
    display: flex;
    gap: 7vw;
    border-left: 1px solid #2c3745;
    padding-inline-start: 7vw;
    padding-inline-end: 10px;
  }

  &__screen-button,
  .screen-button {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 5px;
    cursor: pointer;
    color: #596370;

    &__text {
      font-weight: 500;
      transition: 200ms ease all;
      white-space: nowrap;
    }

    &__count {
      font-size: 10px;
      color: #a6a8bf;
      background: #202934;
      padding: 4px;
      border-radius: 5px;
    }

    &._active {
      color: #eee;
    }
  }
}

.header {
  height: $header_height;
  position: absolute;
  background: rgba(20, 28, 38, 0.8);
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px 0 8px;

  &-controls {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 100%;

    &__line {
      height: 100%;
      flex-shrink: 0;
      width: 1px;
      background: #2c3745;
      margin: 0 10px;
    }

    &__logo {
      width: 50px;
      pointer-events: none;
      transform: translateY(1px);
    }

    &__menu {
      display: flex;
      align-items: center;
      padding: 0 12px 0 16px;
      border-radius: 8px;
      background-color: #202934;
      transition: background-color 0.2s;
      cursor: pointer;
      height: 40px;

      .icon {
        color: #12c383;
        transform: scaleY(-1) translateY(-1px);
        transition: transform 0.2s;
        pointer-events: none;
        margin-inline-start: 10px;
        font-size: 16px;

        &._active {
          transform: scaleY(1);
        }
      }
    }

    &__item {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      background-color: #202934;
      transition: background-color 0.2s;
      cursor: pointer;
      color: #eee;
      font-size: 24px;

      &._active,
      &:hover {
        background-color: #08aa70;
      }

      & + .header-controls__item {
        margin-inline-start: 4px;
      }
    }
  }

  &-search {
    position: absolute;
    width: 100%;
    top: 0;
    height: $header_height;

    &__form,
    .form {
      flex-shrink: 0;
      height: $header_height;
      position: relative;
      background: #141c26;
      width: 100%;
      overflow-x: hidden;
      z-index: 11;
      display: flex;
      align-items: center;

      .input-wrapper {
        height: 100%;
        width: 100%;
        background: transparent;

        .input-text__wrap {
          background: transparent;
          box-shadow: none;
        }

        .input-text__main {
          padding: 0;
        }
      }

      &__input {
        height: 100%;
        width: 100%;

        .input-text {
          $placeholder_color: #596370;
          background: none;
          box-shadow: none !important;
          padding: 0;
          height: 100%;

          &::-webkit-input-placeholder {
            color: $placeholder_color;
          }

          &::-moz-placeholder {
            color: $placeholder_color;
          }

          &:-moz-placeholder {
            color: $placeholder_color;
          }

          &:-ms-input-placeholder {
            color: $placeholder_color;
          }

          &__button {
            width: 24px;
            height: 24px;
            font-size: 24px;
            color: #596370;

            &:hover {
              color: #08aa70;
            }
          }
        }
      }
    }

    &__dropdown,
    .dropdown {
      position: absolute;
      top: $header_height;
      inset-inline-start: 0;
      background: #2c3745;
      width: 100%;
      height: calc(100vh - #{$header_height});
      z-index: 10;
      opacity: 0;
      transform: translateY(-100%);
      transition: 200ms ease all;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      padding: 15px;
      @include hidden-scrollbar-y();

      &._expanded {
        opacity: 1;
        transform: translateY(0);
      }

      &__groups,
      .groups {
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-radius: 5px;

        &__group,
        .group {
          &__title {
            font-weight: 500;
            color: #7b868a;
            margin-bottom: 10px;
          }

          &__list,
          .list {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .order {
              transition: 200ms ease all;
              background: rgba(#fff, 0.03);
              height: auto;
              padding: 5px 10px;
              border: 1px solid transparent;

              &._found {
                background: rgba(#fff, 0.1);
              }

              &._selected {
                border-color: rgba(#fff, 0.3);
              }
            }

            &__item {
              background: #343f4e;
              border-radius: 5px;

              &.team,
              .team {
                padding: 8px 10px;
                display: flex;
                flex-direction: column;
                gap: 5px;

                &__title,
                .title {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 5px;

                  &__name {
                    font-weight: 600;
                    font-size: 1.1em;
                  }

                  &__label {
                    color: #ff4e4e;
                    margin-inline-start: 10px;
                    font-weight: 500;
                    font-size: 0.9em;
                  }
                }

                &__job,
                .job {
                  padding: 5px;
                  display: flex;
                  flex-direction: column;
                  gap: 3px;
                  border-radius: 5px;
                  border: 1px solid rgba(#fff, 0.1);
                }
              }
            }
          }
        }

        &__nothing-found,
        .nothing-found {
          padding: 20px 10px;
          display: flex;
          flex-direction: column;
          align-items: center;

          &__icon {
            height: 70px;
            width: 70px;
            margin-bottom: 20px;
          }

          &__title {
            font-weight: 500;
            font-size: 20px;
            margin-bottom: 10px;
          }

          &__description {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #b1b2b9;
            gap: 2px;
            margin-bottom: 20px;
          }

          &__button {
            width: 100%;
          }
        }
      }
    }
  }
}

.summary {
  position: absolute;
  bottom: 0;
  inset-inline-start: 0;
  background: rgba(20, 28, 38, 0.8);
  display: flex;
  height: 88px;
  border-radius: 0 4px 0 0;

  &__body {
    padding: 0 30px 0 45px;
    display: flex;
    align-items: center;

    & ~ .summary__toggle {
      &:before {
        transform: rotate(-90deg);
      }
    }
  }

  &-item {
    flex-shrink: 0;

    & + .summary-item {
      margin-inline-start: 44px;
    }

    &__title {
      font-size: 12px;
    }

    &__value {
      font-size: 22px;
      margin-top: 4px;
    }
  }

  &__toggle {
    cursor: pointer;
    width: 30px;
    font-size: 14px;
    color: #4e5660;
    transition: color 0.2s;

    &:before {
      transform: rotate(90deg);
      transition: transform 0.3s;
    }

    &:hover {
      color: #eee;
    }
  }
}

.team-list {
  position: initial !important;
  &__items-container {
    padding-top: $header_height;
  }
}

.team {
  padding: 4px 10px 0 7px;

  & + .team {
    margin-top: 1px;
  }

  &._expanded {
    padding-top: 8px;
    padding-bottom: 18px;
    background: #202934;
  }

  &:first-child {
    padding-top: 8px;
  }

  &-title {
    display: flex;
    align-items: baseline;
    padding: 8px 8px 12px 1px;
    cursor: pointer;
    overflow: hidden;

    &__icon {
      width: 16px;
      height: 16px;
      margin-inline-end: 4px;
      font-size: 16px;
      color: #08b375;
      align-self: center;
    }

    &__name {
      font-size: 16px;
      font-weight: 500;
      color: #e9eaec;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }

    &__counts {
      font-size: 10px;
      color: #a6a8bf;
      margin-inline-start: 8px;
      flex-shrink: 0;
    }
  }

  &-wrapper {
    padding-bottom: 72px;
  }

  &-offline {
    padding-top: 16px;
    cursor: pointer;
    font-size: 10px;
    color: #a6a8bf;

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      border: 2px solid transparent;
      border-top: 4px solid #a6a8bf;
      margin-inline-end: 4px;
      transition: transform 0.2s;
      transform: scaleY(-1) translateY(2px);
    }

    &._expanded {
      padding-bottom: 10px;

      &:before {
        transform: scaleY(1);
      }
    }

    &__list {
      margin-top: 4px;
    }
  }

  .react-loading-skeleton {
    margin-top: 3px;
    border-radius: 8px;

    &:first-child {
      margin-top: 8px;
    }
  }
}

.courier {
  border-radius: 8px;
  transition: all 0.2s;
  background: #232e3c;
  border: 1px solid #232e3c;

  &._expanded,
  &._open {
    background: #202934;
    border: 1px solid #2c3745;

    .courier-title__name {
      font-weight: 500;
    }

    .courier-title__arrow {
      transform: translateY(-50%);
      color: #7b868a;
    }

    .courier__body {
      display: flex;
    }
  }

  &._expanded {
    &:not(&:first-child) {
      margin-top: 12px !important;
    }

    &:not(&:last-child) {
      margin-bottom: 12px;
    }
  }

  &._open {
    min-height: 150px;
  }

  &._blink {
    animation: blinkInvert 1s 0.5s backwards;
  }

  & + .courier {
    margin-top: 2px;
  }

  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    font-size: 18px;
    color: #7b868a;
    position: relative;
    border-radius: 50%;
    flex-shrink: 0;

    &:before {
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      inset-inline-start: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      z-index: 1;
      pointer-events: none;
    }

    &._online {
      color: #e9eaec;
      font-size: 14px;

      &:after {
        background: #8157e6;
      }
    }

    &._idle {
      color: #19222d;
      font-size: 14px;

      &:after {
        background: #aadb1e;
      }
    }

    &._assigned_to_job {
      color: #19222d;
      font-size: 14px;

      &:after {
        background: #ffd600;
      }
    }

    &._picking_up {
      color: #e9eaec;
      font-size: 14px;

      &:after {
        background: #08b375;
      }
    }

    &._delivering {
      color: #e9eaec;
      font-size: 14px;

      &:after {
        background: #147ad9;
      }
    }

    &._heading_to_base {
      color: #147ad9;
      font-size: 14px;

      &:after {
        background: #eee;
        border: 1px solid #147ad9;
      }
    }

    &._not_responding {
      color: #e9eaec;
      font-size: 14px;

      &:after {
        background: #c31212;
      }
    }

    &._unknown {
      color: #eee;
      font-size: 14px;

      &:after {
        background: #7b868a;
      }
    }
  }

  &-title {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 26px 0 12px;
    overflow: hidden;
    height: 34px;

    &__name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      color: #eee;
      width: 100%;
    }

    &__status {
      margin-inline-start: 10px;
      font-size: 12px;
      color: #a6a8bf;
      margin-top: 2px;
      white-space: nowrap;
      text-align: right;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    &__info {
      width: 18px;
      height: 34px;
      flex-shrink: 0;
      margin-inline-start: 4px;
      position: relative;
      color: #a6a8bf;
      transition: color 0.2s;
      cursor: pointer;

      &:hover {
        color: #eee;
      }

      div {
        position: absolute;
        pointer-events: none;
        width: 3px;
        height: 3px;
        top: 50%;
        inset-inline-start: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background: currentColor;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 0;
          inset-inline-start: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: currentColor;
        }

        &:before {
          transform: translateY(-5px);
        }

        &:after {
          transform: translateY(5px);
        }
      }
    }

    &__arrow {
      position: absolute;
      width: 16px;
      height: 16px;
      font-size: 14px;
      pointer-events: none;
      color: #596370;
      top: 50%;
      inset-inline-end: 6px;
      margin-top: 1px;
      transform: translateY(-50%) scaleY(-1);
      transition: all 0.2s;
    }

    .courier-icon {
      margin-inline-end: 10px;
    }
  }

  &__body {
    padding: 8px 9px 8px 10px;
    flex-direction: column;
    gap: 4px;
    display: none;
  }

  &__change_route {
    cursor: pointer;
    color: #08b375;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
  }

  &__buttons_container {
    display: flex;
    padding-left: 9px;
    gap: 10px;
    margin-bottom: 10px;
  }

  &__button {
    flex: 0.5;
    height: 32px;
  }
}

.order {
  height: 45px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  padding: 6px 20px 0 8px;
  white-space: nowrap;
  background: #343f4e;
  border-radius: 6px;

  &__tooltip {
    padding: 2px 5px;
    inset-inline-start: 28px;
    font-size: 12px;
    width: auto;
    background-color: щ;
    position: absolute;
    top: 20px;
    background-color: #596370;
    z-index: 100;
  }

  & + .order {
    margin-top: 2px;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 5;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    pointer-events: none;
    border: 1px solid #a6a8bf;
    transition: opacity 0.2s;
    opacity: 0;
  }

  &:hover {
    &:after {
      opacity: 0.4;
    }
  }

  &._disabled {
    &:hover {
      opacity: 0.6;
      .order__lock_icon {
        display: block;
      }
    }
  }

  &._expired {
    &:after {
      opacity: 1;
      border-color: #ff2727;
    }
  }

  &._active {
    &:after {
      opacity: 1;
      border-color: #a6a8bf;
    }

    &._expired {
      &:after {
        box-shadow: 0 0 0 1px #343f4e inset, 0 0 0 2px #ff2727 inset;
      }
    }
  }

  &._blink {
    animation: blinkInvert 1s 0.5s backwards;
  }

  &__lock_icon {
    position: absolute;
    right: 7.5px;
    top: 7.5px;
    width: 10px;
    height: 10px;
    display: none;
  }

  &__bg {
    z-index: 1;
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    height: 100%;

    &._green {
      background-color: #364847;
    }

    &._yellow {
      background-color: #404036;
    }

    &._red {
      background-color: #4a3039;
    }
  }

  &__top,
  &__bottom {
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: baseline;
    z-index: 2;
    pointer-events: none;
  }

  &__top {
    pointer-events: all;
  }

  &__bottom {
    padding-inline-start: 20px;
    margin-top: 2px;
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin-inline-end: 4px;
    flex-shrink: 0;
    align-self: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__number {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    color: #eee;
    margin-inline-end: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 40%;
    &.isSlot {
      color: #f56f42;
    }
  }

  &__address {
    font-size: 11px;
    color: #a6a8bf;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 70%;
  }

  &__counts {
    color: #e9eaec;
    font-size: 14px;
    flex-shrink: 0;
  }

  &__arrow {
    width: 16px;
    height: 7px;
    flex-shrink: 0;
    margin: 0 6px;
    background: url($img_dir + 'arrow_h.svg') center center no-repeat;
    background-size: contain;
  }

  &__name {
    font-size: 12px;
    color: #a6a8bf;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-inline-end: 6px;
  }

  &__eta {
    font-size: 11px;
    color: #a6a8bf;
    flex-shrink: 0;
    margin-inline-start: auto;
  }
}

.gm-style {
  background: #19222d !important;
}

.spinner {
  @include icomoonInit();
  width: 22px;
  height: 22px;
  animation: rotateCW 1.3s linear infinite;
  pointer-events: none;
  font-size: 22px;

  &:before {
    content: $icon-spinner;
  }
}

.alert {
  z-index: $zindex_alert;
  position: fixed;
  bottom: 24px;
  inset-inline-start: 0;
  width: 100%;
  animation: bounceInUp 0.5s backwards;
  display: flex;
  justify-content: center;

  &._error .alert__body {
    background: #bd2525;
  }

  &._warning .alert__body {
    background: #d39317;
  }

  &._success .alert__body {
    background: #08b375;
  }

  &__body {
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    min-height: 40px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3) inset;
    color: #fff;
    bottom: 0;
    cursor: pointer;
    width: 410px;
  }

  &__close {
    font-size: 18px;
    margin-inline-start: 30px;
  }
}

.auth {
  display: flex;
  min-height: 100svh;
  width: 100%;
  height: 100%;
  background: url($img_dir + 'auth_page_bg.jpg') center center no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;

  &-popover {
    position: absolute;
    width: 440px;
    max-width: 90vw;
    border-radius: 12px;
    background: #19222d;
    padding: 32px;
    overflow: hidden;

    &__logo {
      width: 90px;
      display: block;
      margin: 16px auto;
      pointer-events: none;
    }

    &__title {
      margin-top: 36px;
      text-align: center;
      color: #eee;
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
      margin-bottom: 18px;
    }

    &__text {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #eee;
      margin-bottom: 18px;

      span {
        color: #12c383;
      }
    }

    &__code {
      width: 100% !important;

      > div {
        display: flex;
        gap: 6px;
      }

      input {
        background: #202934 !important;
        border-radius: 4px !important;
        box-shadow: 0 0 0 1px #2c3745 !important;
        transition: box-shadow 0.2s;
        height: 48px !important;
        width: 100% !important;
        padding: 0 !important;
        font-size: 18px !important;
        font-weight: 500 !important;
        font-family: $font_pt_root_ui !important;
        color: #eee !important;
        border: 0 !important;
        caret-color: #fff !important;

        &:hover {
          box-shadow: 0 0 0 1px #3a4758 !important;
        }

        &:focus {
          box-shadow: 0 0 0 3px #3a4758 !important;
        }
      }
    }

    &__timer {
      margin-top: 18px;
      text-align: center;
      font-size: 14px;
      line-height: 18px;
      color: #7b868a;
    }

    &__btn {
      &-wrap {
        display: flex;
        margin-top: 24px;

        .button {
          width: 100%;
          font-size: 16px;
          flex-shrink: 1;

          & + .button {
            margin-inline-start: 20px;
          }
        }
      }

      &-back .icon {
        margin-inline-end: 8px;
        font-size: 20px;
      }

      &-submit {
        width: 100%;
        margin-top: 24px;
        font-size: 16px;

        .spinner,
        .icon {
          margin-inline-start: 8px;
        }

        .icon {
          transform: rotate(90deg);
          font-size: 18px;
        }
      }
    }
  }

  &-input {
    &__password {
      margin-top: 16px;
    }
  }

  &-tabs {
    display: flex;
    flex-direction: row;
    position: relative;
    padding-top: 16px;
    margin-bottom: 24px;
    border-bottom: 2px solid rgb(8, 179, 117);
    border-top-color: rgb(8, 179, 117);
    border-right-color: rgb(8, 179, 117);
    border-left-color: rgb(8, 179, 117);

    &__tab {
      flex: 1;
      text-align: center;
      padding: 10px 0;
      cursor: pointer;
      color: #7a7a7a;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      color: #eee;
    }

    &__tab.active {
      color: #00b894;
    }

    &__underline {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 50%;
      background-color: #00b894;
      transition: left 0.3s ease;
    }

    &__underline.active {
      left: 50%;
    }
  }
}

.map {
  &-pin {
    transform: translate(-50%, -100%);
    z-index: 1;
    position: relative;

    .order-info-bar {
      min-width: 300px;
      bottom: 40px;
      inset-inline-start: -5px;
      position: absolute;
      display: none;
      animation: fadeIn 0.2s 0.1s backwards;
      border-radius: 5px;
      overflow: hidden;
      background: #343f4e;

      & > div {
        padding: 8px 12px;
      }

      &._earlier .time {
        background: #05c582;
      }

      &._late .time {
        background: #d02e0d;
      }

      &._zero-min .time {
        background: #05c582 !important;
      }

      &__time,
      .time {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__value {
          font-size: 1.7em;
          font-weight: 500;
        }
      }

      &__info,
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;

        &__external-id {
          max-width: 450px;
          font-size: 1.2em;
          font-weight: 500;
          @include text-ellipsis();
        }

        &__address {
          white-space: nowrap;
          color: #a6a8bf;
        }
      }
      &:hover {
        transform: scale(1.2);
        cursor: pointer;
      }
    }

    &._wh {
      pointer-events: none;
      width: 20px;
      height: 27px;
    }

    &._courier {
      position: relative;
      transform: translate(-50%, -50%);
      width: 18px;
      height: 18px;

      &:hover {
        z-index: $zindex_pin_hover;
      }

      &._active {
        z-index: $zindex_pin_active;
        pointer-events: none;

        .courier-icon {
          transform: scale(1.5);
        }

        .map-label {
          display: flex;
        }
      }

      .courier-icon {
        cursor: pointer;
        transition: transform 0.2s;

        &:hover {
          transform: scale(1.5);

          & ~ .map-label {
            display: flex;
          }
        }
      }
    }

    &._task {
      position: relative;
      width: 20px;
      height: 20px;

      &:hover {
        z-index: $zindex_pin_hover;
      }

      &._active {
        z-index: $zindex_pin_active;
        pointer-events: none;

        > img {
          transform: scale(1.5);
        }

        .order {
          display: block;
        }

        .order-info-bar {
          display: flex;
        }

        .task-eta-label {
          display: flex;
        }
      }

      > img {
        cursor: pointer;
        transition: transform 0.2s;
        transform-origin: 50% 100%;

        &:hover {
          transform: scale(1.5);

          & ~ .order {
            display: block;
          }

          & ~ .order-info-bar {
            display: flex;
          }

          & ~ .task-eta-label {
            display: flex;
          }
        }
      }

      .order {
        width: 300px;
        position: absolute;
        bottom: 35px;
        inset-inline-start: -5px;
        display: none;
        animation: fadeIn 0.2s 0.1s backwards;

        &:after {
          opacity: 1;
        }

        &__bottom {
          padding-inline-start: 0;
          margin-top: 5px;
        }
      }

      .task-eta-label {
        bottom: 85px;
        inset-inline-start: -5px;
        position: absolute;
        display: none;
        animation: fadeIn 0.2s 0.1s backwards;
        border-radius: 5px;
        overflow: hidden;
        background: #343f4e;

        & > div {
          padding: 8px 12px;
        }

        &__time,
        .time {
          background: #05c582;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &__value {
            font-size: 1.7em;
            font-weight: 500;
          }
        }

        &__address,
        .address {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 3px;

          &__name {
            max-width: 450px;
            font-size: 1.2em;
            font-weight: 500;
            @include text-ellipsis();
          }
        }
      }
    }

    &._courier-cluster,
    &._task-cluster {
      .count {
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        color: #141c26;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        cursor: pointer;
        transition: transform 0.2s;
      }
    }

    &._courier-cluster {
      transform: translate(-50%, -50%);
      background-image: url($img_dir + 'pin_courier_cluster.svg');
      padding-inline-end: 3px;

      &:hover {
        .count {
          transform: scale(1.5);
        }
      }

      .count {
        font-size: 12px;
        background-image: url($img_dir + 'pin_courier_cluster.svg');
        padding-inline-end: 3px;
      }

      &._active {
        z-index: $zindex_pin_active;

        .count {
          transform: scale(1.5);
        }

        .couriers {
          display: flex;
        }
      }

      .couriers {
        display: none;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        padding-bottom: 10px;
        inset-inline-start: -5px;
        animation: fadeIn 0.2s 0.1s backwards;

        .map-label {
          display: flex;
          position: relative;
          margin-bottom: 5px;
          pointer-events: all;
          border: 1px solid transparent;
          transition: 200ms ease all;

          .icon {
            margin-inline-end: 5px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &._active {
            border-color: #a6a8bf;
          }

          &:hover {
            background: #2d3642;
          }
        }
      }
    }

    &._task-cluster {
      background-image: url($img_dir + 'pin_task_cluster.svg');
      padding-bottom: 4px;
      padding-inline-end: 2px;
      font-size: 11px;

      &:hover {
        .count {
          transform: scale(1.2);
        }
      }

      .count {
        background-image: url($img_dir + 'pin_task_cluster.svg');
        padding-bottom: 4px;
        letter-spacing: -2px;
        padding-inline-end: 2px;
        font-size: 11px;
        transform-origin: 50% 100%;
      }

      &._active {
        z-index: $zindex_pin_active;

        .count {
          transform: scale(1.5);
        }

        .orders .order-info-bar {
          display: flex;
        }
      }

      .orders {
        display: flex;
        flex-direction: column;
        gap: 5px;
        position: absolute;
        bottom: 5px;
        padding-bottom: 40px;
        inset-inline-start: -5px;
        animation: fadeIn 0.2s 0.1s backwards;

        .order-info-bar {
          position: static;
          transition: 200ms ease all;
          border: 1px solid transparent;

          &._active {
            border-color: rgba(#fff, 0.3);
          }

          &:hover {
            background: #2d3642;
          }
        }
      }
    }
  }

  &-label {
    pointer-events: none;
    bottom: 30px;
    inset-inline-start: -5px;
    border-radius: 4px;
    background: #34404e;
    padding: 8px 12px;
    white-space: nowrap;
    font-family: $font_pt_root_ui;
    position: absolute;
    display: none;
    align-items: flex-end;
    animation: fadeIn 0.2s 0.1s backwards;

    &__name {
      max-width: 200px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      font-weight: 500;
      color: #eee;

      & + .map-label__status {
        margin-inline-start: 10px;
      }
    }

    &__status {
      font-size: 12px;
      color: #a6a8bf;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.drop-here {
  border-radius: 8px;
  border: 2px dashed #232e3c;
  padding: 30px 0;
  position: relative;
  transition: border-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;

  &:before {
    content: attr(data-text);
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #a6a8bf;
    font-size: 14px;
    top: calc(50% - 7px);
    white-space: nowrap;
    position: absolute;
  }
  &._hover:before {
    top: 7px;
  }
  &._hover {
    border-color: #a6a8bf;
  }
}

.job {
  padding-inline-start: 9px;

  &._isDraggable {
    max-height: 34px;
  }

  &._group {
    display: flex;
    flex-direction: column;
    gap: 2px;
    position: relative;
    padding: 4px 0 4px 8px;
    background: linear-gradient(#202934, #202934) padding-box,
      linear-gradient(to right, #596370, rgba(0, 0, 0, 0) 80%) border-box;
    border: 1px solid transparent;
    border-right: none;
    border-radius: 4px;
  }
}

.jobDragCard {
  flex: 1;
  align-items: center;
  color: #fff;
}

.infoWindow {
  width: max-content;
  flex-direction: column;
  align-items: flex-start;
  padding: 4.5px 20px 2.5px 8px;
  background: #343f4e;
  border: 1px solid #a6a8bf;
  font-family: $font_pt_root_ui;
  border-radius: 4px;
  display: none;
  position: absolute;
  top: -58px;
  overflow: hidden;
  white-space: nowrap;

  &__bg {
    z-index: 1;
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    height: 100%;

    &._green {
      background: #364847;
    }

    &._yellow {
      background: #404036;
    }

    &._red {
      background: #4a3039;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    inset-inline-start: 0;
    width: 20px;
    height: 20px;
  }

  &-section {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1px;

    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #eee;
      line-height: 1.16;
    }

    &__counter {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #eee;
      line-height: 1.28;
    }

    &__text {
      font-weight: normal;
      font-size: 11px;
      color: #7b868a;
      margin: 0 10px;
    }

    &__name {
      font-weight: normal;
      font-size: 12px;
      color: #7b868a;
      margin-inline-end: 55px;
    }

    &__icon {
      margin: 0 6px;
    }

    &__date {
      font-weight: normal;
      font-size: 11px;
      color: #7b868a;
      align-self: end;
      margin-inline-start: auto;
    }
  }
}

.add-team-form {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__buttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;

    & > .button {
      flex: 1 1;
    }
  }
}

.filter-form {
  &__list {
    margin-top: 20px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 0;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-inline-end: 20px;
  }

  &__icon {
    width: 16px;
    flex-shrink: 0;
    margin-inline-end: 4px;
  }

  &__name {
    color: #eee;
    font-size: 14px;

    span {
      color: #08aa70;
      margin-inline-start: 8px;
    }
  }

  &__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;

    & > .button {
      flex: 1 1;
    }
  }
}

.add-courier-form {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__buttons {
    margin-top: 15px;
    display: flex;
    gap: 10px;
    width: 100%;

    & > .button {
      flex: 1 1;
    }
  }
}

.edit-courier-form {
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 0 28px;

  &__input,
  .input {
    &__text {
      font-weight: 500;
      margin-bottom: 10px;
    }

    &__content {
      &.transport {
        display: flex;
        gap: 5px;

        .button {
          padding: 0;
          width: 40px;
          font-size: 1.7em;
        }
      }

      &.capacity {
        display: flex;
        align-items: center;
        gap: 10px;

        .capacity__input {
          width: 50px;
        }

        .input-text__main {
          padding: 0;
        }
      }
    }

    &._checkbox {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      & > div {
        margin: 0;
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 10px;

    button {
      flex: 1;
    }
  }
}

.switch {
  &__list,
  .list {
    display: flex;
    gap: 5px;
    background: #1a252f;
    padding: 5px;
    border-radius: 8px;
    position: relative;

    &__state,
    .state {
      cursor: pointer;
      flex: 1;
      text-align: center;
      padding: 8px;
      border-radius: 4px;
      color: #a6a8bf;
      font-weight: 500;
      z-index: 10;
      transition: 200ms ease all;
      @include text-ellipsis();

      &._active {
        color: #eee;
      }
    }

    &__bg {
      background: #596370;
      position: absolute;
      border-radius: 4px;
      transition: 200ms ease all;

      &._gray {
        background: #596370;
      }

      &._green {
        background: #08b375;
      }
    }
  }
}

.multiline-ellipsis {
  &__text {
    @include multiline-ellipsis(3);
    position: relative;
    padding-inline-end: 45px;
  }

  &__button {
    color: #08aa70;
    cursor: pointer;
    font-weight: 500;
    position: absolute;
    inset-inline-end: 0;
    bottom: 0;
  }

  &._full {
    .multiline-ellipsis__text {
      padding: 0;
      display: block;
      -webkit-line-clamp: initial;
      -webkit-box-orient: initial;
      overflow: initial;
      text-overflow: initial;
    }
  }
}

span._secondary {
  color: #7b868a;
}

.app-version {
  position: absolute;
  bottom: 20px;
  inset-inline-end: 20px;
  cursor: pointer;
}

.slots {
  flex-direction: column;
  margin-top: 24px;
  padding: 16px 20px;
  min-height: 100px;
  width: 100%;
  &.__expanded {
    background-color: #202934;
  }
  &-body {
    display: none;
    &.__expanded {
      display: block;
    }
  }
}

.slot {
  padding: 10px 0px;
  &-body {
    padding-inline-start: 5px;
    display: none;
    &.__expanded {
      display: block;
    }
  }
  &-headerTitle {
    font-size: 15px;
    min-width: 100px;
  }
}

.interval {
  padding: 10px 0px;
  &-body {
    padding-top: 16px;
    margin-inline-start: -5px;
    display: none;
    &.__expanded {
      display: block;
    }
  }
  &-headerTitle {
    font-size: 12px;
    min-width: 70px;
  }
}
