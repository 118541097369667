@import "config";
@import "icon_names";

.icon {
  @include icomoonInit();

  &:after {
    pointer-events: none;
    position: relative;
    z-index: 2;
  }
}

[dir='rtl'] {
  .icon {
    scale: -1 1;
  }
}

.icon-clock {
  &:before {
    content: $icon-clock;
  }
}

.icon-pin-outline {
  &:before {
    content: $icon-pin-outline;
  }
}

.icon-bin {
  &:before {
    content: $icon-bin;
  }
}

.icon-pen {
  &:before {
    content: $icon-pen;
  }
}

.icon-headphones {
  &:before {
    content: $icon-headphones;
  }
}

.icon-star {
  &:before {
    content: $icon-star;
  }
}

.icon-waiting_for_pickup {
  &:before {
    content: $icon-waiting_for_pickup;
  }
}

.icon-picked {
  &:before {
    content: $icon-picked;
  }
}

.icon-missing {
  &:before {
    content: $icon-missing;
  }
}

.icon-delivered {
  &:before {
    content: $icon-delivered;
  }
}

.icon-rejected {
  &:before {
    content: $icon-rejected;
  }
}

.icon-returned {
  &:before {
    content: $icon-returned;
  }
}

.icon-not_returned {
  &:before {
    content: $icon-not_returned;
  }
}

.icon-exit {
  &:before {
    content: $icon-exit;
  }
}

.icon-team {
  &:before {
    content: $icon-team;
  }
}

.icon-pin {
  &:before {
    content: $icon-pin;
  }
}

.icon-map {
  &:before {
    content: $icon-map;
  }
}

.icon-home {
  &:before {
    content: $icon-home;
  }
}

.icon-dashboard {
  &:before {
    content: $icon-dashboard;
  }
}

.icon-glass {
  &:before {
    content: $icon-glass;
  }
}

.icon-snowflake {
  &:before {
    content: $icon-snowflake;
  }
}

.icon-footer {
  &:before {
    content: $icon-footer;
  }
}

.icon-truck {
  &:before {
    content: $icon-truck;
  }
}

.icon-motorbike {
  &:before {
    content: $icon-motorbike;
  }
}

.icon-car {
  &:before {
    content: $icon-car;
  }
}

.icon-bicycle {
  &:before {
    content: $icon-bicycle;
  }
}

.icon-attention-sign {
  &:before {
    content: $icon-attention-sign;
  }
}

.icon-check {
  &:before {
    content: $icon-check;
  }
}

.icon-minus {
  &:before {
    content: $icon-minus;
  }
}

.icon-plus {
  &:before {
    content: $icon-plus;
  }
}

.icon-sort {
  &:before {
    content: $icon-sort;
  }
}

.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}

.icon-passport {
  &:before {
    content: $icon-passport;
  }
}

.icon-hand-drag {
  &:before {
    content: $icon-hand-drag;
  }
}

.icon-spinner {
  &:before {
    content: $icon-spinner;
  }
}

.icon-settings {
  &:before {
    content: $icon-settings;
  }
}

.icon-magnifier {
  &:before {
    content: $icon-magnifier;
  }
}

.icon-close {
  &:before {
    content: $icon-close;
  }
}

.icon-check-thin {
  &:before {
    content: $icon-check-thin;
  }
}

.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}

.icon-add {
  &:before {
    content: $icon-add;
  }
}

.icon-copy {
  &:before {
    content: $icon-copy;
  }
}

.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
