$icon-clock: "\e92a";
$icon-pin-outline: "\e92b";
$icon-bin: "\e927";
$icon-pen: "\e928";
$icon-headphones: "\e929";
$icon-star: "\e926";
$icon-waiting_for_pickup: "\e91f";
$icon-picked: "\e920";
$icon-missing: "\e921";
$icon-delivered: "\e922";
$icon-rejected: "\e923";
$icon-returned: "\e924";
$icon-not_returned: "\e925";
$icon-exit: "\e91e";
$icon-team: "\e91c";
$icon-pin: "\e91d";
$icon-map: "\e919";
$icon-home: "\e91a";
$icon-dashboard: "\e91b";
$icon-glass: "\e917";
$icon-snowflake: "\e918";
$icon-footer: "\e90b";
$icon-truck: "\e90c";
$icon-motorbike: "\e90d";
$icon-car: "\e90e";
$icon-bicycle: "\e90f";
$icon-attention-sign: "\e910";
$icon-check: "\e911";
$icon-minus: "\e912";
$icon-plus: "\e913";
$icon-sort: "\e914";
$icon-chevron-up: "\e915";
$icon-passport: "\e916";
$icon-hand-drag: "\e907";
$icon-spinner: "\e908";
$icon-settings: "\e909";
$icon-magnifier: "\e90a";
$icon-close: "\e904";
$icon-check-thin: "\e905";
$icon-arrow-left: "\e906";
$icon-add: "\e901";
$icon-copy: "\e902";
$icon-filter: "\e903";
