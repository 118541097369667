@mixin text-ellipsis($show-full: false) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: 100ms ease all;

  @if $show-full {
    &:hover {
      text-overflow: initial;
      white-space: normal;
      overflow: visible;
      max-width: unset;
      width: unset;
      position: absolute;
      z-index: 100;
      background: inherit;
      padding: 3px;
      margin: -3px;
    }
  }
}

@mixin multiline-ellipsis($lines: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

@mixin hidden-scrollbar-y() {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin hidden-scrollbar-x() {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin gray-scrollbar($axis: "y") {
  @if $axis == "y" {
    overflow-y: scroll;
  } @else {
    overflow-x: scroll;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    padding: 5px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #3a4758;
    border-radius: 5px;
  }
}

