@import "config";

@font-face {
  font-family: "PT Root UI";
  src: url($fonts_dir + "PT-Root-UI_Regular.woff2") format("woff2"),
  url($fonts_dir + "PT-Root-UI_Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PT Root UI";
  src: url($fonts_dir + "PT-Root-UI_Light.woff2") format("woff2"),
  url($fonts_dir + "PT-Root-UI_Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "PT Root UI";
  src: url($fonts_dir + "PT-Root-UI_Medium.woff2") format("woff2"),
  url($fonts_dir + "PT-Root-UI_Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "PT Root UI";
  src: url($fonts_dir + "PT-Root-UI_Bold.woff2") format("woff2"),
  url($fonts_dir + "PT-Root-UI_Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "icomoon";
  src: url($fonts_dir + "icomoon.ttf") format("truetype"),
  url($fonts_dir + "icomoon.woff") format("woff"),
  url($fonts_dir + "icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
