// @use '../../styles/company/index' as *;

.root {
  padding: 0;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  margin: 0 4px;

  img {
    height: 16px;
    width: 16px;
  }

  &:hover {
    cursor: pointer;
  }
}
