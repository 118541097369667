$img_dir: "../img/";
$fonts_dir: "../fonts/";

$font_pt_root_ui: "PT Root UI", sans-serif;
$font_arial: "Arial", sans-serif;
$font_icomoon: "icomoon" !important;

$frame_teams_width: 363px;
$header_height: 49px;
$mobile-header-height: 60px;
$mobile-footer-height: 56px;
$mobile-sidebar-width: 260px;
$zindex_pin_active: 100;
$zindex_pin_hover: 101;
$zindex_alert: 120;

$back-in-ease: cubic-bezier(0.52, -0.38, 0.49, 1.31);

@mixin icomoonInit {
  font-family: $font_icomoon;
  flex-shrink: 0;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
