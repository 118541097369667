@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scaleOut {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.5);
  }
}

@keyframes rotateCW {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 500px, 0) scaleY(5);
  }

  65% {
    opacity: 1;
    transform: translate3d(0, -15px, 0) scaleY(0.9);
  }

  85% {
    transform: translate3d(0, 3px, 0) scaleY(0.95);
  }

  90% {
    transform: translate3d(0, -2px, 0) scaleY(0.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, -500px, 0) scaleY(5);
  }

  65% {
    opacity: 1;
    transform: translate3d(0, 15px, 0) scaleY(0.9);
  }

  85% {
    transform: translate3d(0, -3px, 0) scaleY(0.95);
  }

  90% {
    transform: translate3d(0, 2px, 0) scaleY(0.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes blinkInvert {
  0% {
    filter: invert(0);
  }

  25% {
    filter: invert(1);
  }

  50% {
    filter: invert(0);
  }

  75% {
    filter: invert(1);
  }

  100% {
    filter: invert(0);
  }
}
