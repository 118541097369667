@use "sass:math";
@import "config";

.d {
  &-none {
    display: none !important;
  }

  &-flex {
    display: flex !important;
  }

  &-block {
    display: block !important;
  }

  &-inline {
    display: inline !important;

    &-block {
      display: inline-block !important;
    }

    &-flex {
      display: inline-flex !important;
    }
  }
}

.flex {
  &-center {
    align-items: center !important;
    justify-content: center !important;
  }

  &-shrink {
    &-0 {
      flex-shrink: 0 !important;
    }

    &-1 {
      flex-shrink: 1 !important;
    }
  }

  &-grow {
    &-1 {
      flex-grow: 1;
    }
  }

  &-direction {
    &-column {
      flex-direction: column !important;
    }
  }

  &-wrap {
    flex-wrap: wrap !important;
  }
}

.align-items {
  &-start {
    align-items: flex-start !important;
  }

  &-baseline {
    align-items: baseline !important;
  }

  &-center {
    align-items: center !important;
  }

  &-end {
    align-items: flex-end !important;
  }
}

.align-self {
  &-start {
    align-self: flex-start !important;
  }

  &-center {
    align-self: center;
  }

  &-end {
    align-self: flex-end !important;
  }
}

.justify-content {
  &-between {
    justify-content: space-between !important;
  }

  &-center {
    justify-content: center !important;
  }

  &-end {
    justify-content: flex-end !important;
  }

  &-start {
    justify-content: flex-start !important;
  }
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.text {
  &-center {
    text-align: center !important;
  }

  &-right {
    text-align: right !important;
  }

  &-top {
    vertical-align: top !important;
  }

  &-truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-nowrap {
    white-space: nowrap !important;
  }

  &-wrap {
    white-space: normal !important;
  }

  &-underline {
    text-decoration: underline !important;
  }

  &-capitalize {
    text-transform: capitalize !important;
  }

  &-break-word {
    overflow-wrap: break-word !important;
  }

  &-break-line {
    line-break: anywhere !important;
  }
}

.c {
  &-transparent {
    color: transparent !important;
  }

  &-cc {
    color: currentColor !important;
  }

  &-bg-none {
    background: none !important;
  }
}

.f {
  &w {
    &-400 {
      font-weight: 400 !important;
    }

    &-300 {
      font-weight: 400 !important;
    }

    &-500 {
      font-weight: 500 !important;
    }

    &-700 {
      font-weight: 700 !important;
    }
  }
}

.lh-1 {
  line-height: 1 !important;
}

.w-100p {
  width: 100% !important;
}

.m {
  $sizes: 10, 0;

  @each $size in $sizes {
    &-#{$size} {
      margin: #{$size}px !important;
    }

    &t-#{$size} {
      margin-top: #{$size}px !important;
    }

    &t-n#{$size} {
      margin-top: -#{$size}px !important;
    }

    &b-#{$size} {
      margin-bottom: #{$size}px !important;
    }

    &b-n#{$size} {
      margin-bottom: -#{$size}px !important;
    }

    &l-#{$size} {
      margin-inline-start: #{$size}px !important;
    }

    &l-n#{$size} {
      margin-inline-start: -#{$size}px !important;
    }

    &r-#{$size} {
      margin-inline-end: #{$size}px !important;
    }

    &x-#{$size} {
      margin-inline-start: #{$size}px !important;
      margin-inline-end: #{$size}px !important;
    }

    &x-n#{$size} {
      margin-inline-start: -#{$size}px !important;
      margin-inline-end: -#{$size}px !important;
    }

    &y-#{$size} {
      margin-top: #{$size}px !important;
      margin-bottom: #{$size}px !important;
    }
  }

  &t-auto {
    margin-top: auto !important;
  }

  &b-auto {
    margin-bottom: auto !important;
  }

  &l-auto {
    margin-inline-start: auto !important;
  }

  &x-auto {
    margin-inline-start: auto !important;
    margin-inline-end: auto !important;
  }

  &y-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

.p {
  $sizes: 0;

  @each $size in $sizes {
    &-#{$size} {
      padding: #{$size}px !important;
    }

    &t-#{$size} {
      padding-top: #{$size}px !important;
    }

    &b-#{$size} {
      padding-bottom: #{$size}px !important;
    }

    &l-#{$size} {
      padding-inline-start: #{$size}px !important;
    }

    &r-#{$size} {
      padding-inline-end: #{$size}px !important;
    }

    &x-#{$size} {
      padding-inline-start: #{$size}px !important;
      padding-inline-end: #{$size}px !important;
    }

    &y-#{$size} {
      padding-top: #{$size}px !important;
      padding-bottom: #{$size}px !important;
    }
  }
}

.z {
  $sizes: 0, 1, 2, 3, 4;

  @each $size in $sizes {
    &-#{$size} {
      z-index: #{$size} !important;
    }
  }

  &-auto {
    z-index: auto !important;
  }
}

.o {
  $sizes: 1, 2, 3, 4, 5, 6, 7, 8, 9;

  @each $size in $sizes {
    &-#{$size} {
      opacity: math.div($size, 10) !important;
    }
  }
}

.position {
  &-fixed {
    position: fixed !important;
  }

  &-absolute {
    position: absolute !important;
  }

  &-relative {
    position: relative !important;
  }

  &-unset {
    position: unset !important;
  }
}

.overflow {
  &-hidden {
    overflow: hidden !important;
  }

  &-auto {
    overflow: auto !important;
  }
}

.pe {
  &-n {
    pointer-events: none !important;
  }
}

.border {
  &-0 {
    border: 0 !important;
  }
}

.shadow {
  &-none {
    box-shadow: none !important;
  }
}
